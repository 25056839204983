// eslint-disable-next-line import/no-cycle

import {
    BoddRole,
    MerchantAdminRole,
    MerchantOwnerRole,
    MerchantRole,
    QualityAssuranceRole,
    SystemOwnerRole,
} from '../auth/auth-constants';
import { BoddNavigationItem } from '../model/bodd-navigation-item.model';

export class Navigation {
    static sideNav: BoddNavigationItem[] = [
        {
            title: 'Scans',
            url: '/%region%/scans',
            security: [BoddRole, SystemOwnerRole, QualityAssuranceRole, MerchantAdminRole, MerchantOwnerRole, MerchantRole],
            icon: 'scans',
        },
        {
            title: 'Scanners',
            url: '/scanners',
            security: [BoddRole, SystemOwnerRole, QualityAssuranceRole, MerchantAdminRole, MerchantOwnerRole],
            icon: 'scanners',
        },
        {
            title: 'Profiles',
            url: '/profiles',
            security: [BoddRole, SystemOwnerRole, MerchantAdminRole, MerchantOwnerRole],
            icon: 'profile',
        },
        {
            title: 'Reports',
            url: '/%region%/reports',
            security: [BoddRole, SystemOwnerRole, MerchantAdminRole, MerchantOwnerRole],
            icon: 'reports',
        },
        {
            title: 'Products',
            url: '/products',
            security: [MerchantAdminRole, MerchantOwnerRole, MerchantRole],
            requiredConfiguration: 'sizeMatchEnabled',
            icon: 'products',
        },
        {
            title: 'Help',
            url: 'https://help.bodd.io/',
            security: [BoddRole, SystemOwnerRole, QualityAssuranceRole, MerchantAdminRole, MerchantOwnerRole, MerchantRole],
            icon: 'help',
        },
        {
            title: 'Merchants',
            url: '/merchants',
            security: [BoddRole, SystemOwnerRole],
            icon: 'merchants',
        },
        {
            title: 'Account',
            url: '/account',
            security: [MerchantAdminRole, MerchantOwnerRole, MerchantRole],
            icon: 'account',
        },
    ];

    // filters navigation based on the roles available.  undefined roles == unauthenticated
    // navigation item with an empty roles array =  auth required, but no specific roles.  undefined roles = unauth ok.
    static filterForRoles(roles: string[] | null, merchantConfiguration: {
        [key: string]: boolean
    } | null): BoddNavigationItem[] {
        return Navigation.sideNav.reduce((a, c) => {
            if (!c.security) {
                if (Navigation.checkConfiguration(c, merchantConfiguration)) {
                    a.push(c); // nav item has no security assigned, can show at any time
                }
            } else if (c.security.length === 0 && roles) {
                if (Navigation.checkConfiguration(c, merchantConfiguration)) {
                    a.push(c); // nav item has empty security, only show if authenticated, roles doesnt matter
                }
            } else if (roles && roles.some((ur) => c.security!.some((s) => ur === s))) {
                if (Navigation.checkConfiguration(c, merchantConfiguration)) {
                    a.push(c); // we have security and roles, only show if role present
                }
            }
            return a;
        }, [] as BoddNavigationItem[]);
    }

    static checkConfiguration(navItem: BoddNavigationItem, merchantConfiguration: {
        [key: string]: boolean
    } | null): boolean {
        if (!merchantConfiguration || !navItem.requiredConfiguration) {
            return true; // there is either no current merchant, or no required config for this item
        }

        return (merchantConfiguration)[navItem.requiredConfiguration];
    }
}

export interface RouteSecurity {
    roles: string[];
}
