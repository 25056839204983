/* eslint-disable max-len */
import React, { FC, useEffect, useRef } from 'react';

interface BoddIconComponentProps {
    clicked?: () => void;
    height?: string;
    width?: string;
}

const BoddIconComponent: FC<BoddIconComponentProps> = (props) => {
    const { clicked, height, width } = props;
    const ref = useRef<HTMLObjectElement>(null);

    const click = () => {
        if (clicked) {
            clicked();
        }
    };

    const attachClick = (e: any) => {
        e.target.contentDocument.addEventListener('click', click);
        e.target.contentDocument.children[0].style.cursor = 'pointer';
    };

    useEffect(() => {
        const obj = ref.current;
        if (obj) {
            obj.addEventListener('load', attachClick);
        }

        return () => {
            if (obj) {
                obj.removeEventListener('load', attachClick);
            }
        };
    }, []);

    return (
        <object
            ref={ref}
            height={height}
            width={width}
            type="image/svg+xml"
            data="/bodd-logo-home.svg"
            data-testid="BoddIconComponent"
            aria-label="Bodd"
        />
    );
};

export default BoddIconComponent;

BoddIconComponent.defaultProps = {
    clicked: undefined,
    height: '60px',
    width: '60px',
};
