import { Authsignal } from '@authsignal/browser';

export default class AuthUtils {
    static getDeviceId() {
        const { atId: authsignalTenantId, ae: authsignalEnabled } = (window as any).boddSettings;
        if (!authsignalEnabled) {
            return '';
        }
        const authsignal = new Authsignal({
            tenantId: authsignalTenantId,
        });
        return authsignal.anonymousId;
    }

    static launchAuthsignal(url: string) {
        const { atId: authsignalTenantId, ae: authsignalEnabled } = (window as any).boddSettings;
        if (!authsignalEnabled) {
            return;
        }
        const authsignal = new Authsignal({
            tenantId: authsignalTenantId,
        });
        authsignal.launch(url);
    }
}
