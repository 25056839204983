/* eslint-disable max-len */
import React, { FC } from 'react';
import { SvgIcon } from '@mui/material';
import ScannerProfileSvg from '../../../../assets/icons/scanner-profile.svg';
import ScansSvg from '../../../../assets/icons/scans.svg';
import ScannersSvg from '../../../../assets/icons/scanners.svg';
import ReportsSvg from '../../../../assets/icons/reports.svg';
import ProductsSvg from '../../../../assets/icons/products.svg';
import HelpSvg from '../../../../assets/icons/help.svg';
import AccountSvg from '../../../../assets/icons/account.svg';
import MerchantsSvg from '../../../../assets/icons/merchants.svg';
import UsersSvg from '../../../../assets/icons/users.svg';
import ImageSvg from '../../../../assets/icons/image.svg';
import UserProfileSvg from '../../../../assets/icons/user-profile.svg';
import TagSvg from '../../../../assets/icons/tag.svg';
import SpreadsheetSvg from '../../../../assets/icons/speadsheet.svg';
import LinkSvg from '../../../../assets/icons/link.svg';
import RestartSvg from '../../../../assets/icons/restart.svg';
import EditSvg from '../../../../assets/icons/edit.svg';
import MeasurementSvg from '../../../../assets/icons/measurement.svg';
import SizeMatchSvg from '../../../../assets/icons/size-match.svg';
import SizeMatchRangeSvg from '../../../../assets/icons/size-match-range.svg';
import ArrowSvg from '../../../../assets/icons/arrow.svg';
import BodyScanSvg from '../../../../assets/icons/body-scan.svg';
import FootScanSvg from '../../../../assets/icons/foot-scan.svg';
import WellnessScanSvg from '../../../../assets/icons/wellness-scan.svg';
import HandScanSvg from '../../../../assets/icons/hand-scan.svg';
import HeadScanSvg from '../../../../assets/icons/head-scan.svg';
import PuzzleSvg from '../../../../assets/icons/puzzle.svg';
import ListSvg from '../../../../assets/icons/list.svg';
import GridSvg from '../../../../assets/icons/grid.svg';
import PointerSvg from '../../../../assets/icons/pointer.svg';
import AccessoriesGarmentSvg from '../../../../assets/icons/accessories-garment.svg';
import BeltGarmentSvg from '../../../../assets/icons/belt-garment.svg';
import FootwearGarmentSvg from '../../../../assets/icons/footwear-garment.svg';
import FullbodyGarmentSvg from '../../../../assets/icons/fullbody-garment.svg';
import GlovesGarmentSvg from '../../../../assets/icons/gloves-garment.svg';
import HeadgearGarmentSvg from '../../../../assets/icons/headgear-garment.svg';
import JumperGarmentSvg from '../../../../assets/icons/jumper-garment.svg';
import OuterwearGarmentSvg from '../../../../assets/icons/outerwear-garment.svg';
import ShirtGarmentSvg from '../../../../assets/icons/shirt-garment.svg';
import ShortsGarmentSvg from '../../../../assets/icons/shorts-garment.svg';
import SkirtGarmentSvg from '../../../../assets/icons/skirt-garment.svg';
import TrousersGarmentSvg from '../../../../assets/icons/trousers-garment.svg';
import TshirtGarmentSvg from '../../../../assets/icons/tshirt-garment.svg';
import OtherGarmentSvg from '../../../../assets/icons/other-garment.svg';
import AddFitVariantSvg from '../../../../assets/icons/add-fit-variant.svg';
import GlobeSvg from '../../../../assets/icons/globe.svg';
import ImpersonateSvg from '../../../../assets/icons/impersonate.svg';
import LogoutSvg from '../../../../assets/icons/logout.svg';
import DocumentSvg from '../../../../assets/icons/document.svg';
import MovieCameraSvg from '../../../../assets/icons/movie-camera.svg';
import AccessGroupSvg from '../../../../assets/icons/access-group.svg';
import ScannerProfilesDiagramSvg from '../../../../assets/scanner-profiles-diagram.svg';
import SizesSvg from '../../../../assets/icons/sizes.svg';
import BoddSvg from '../../../../assets/bodd-logo.svg';

// NOTE ABOUT SVG FILES FOR ICONS
//
// - In the SVG file set fill="currentColor". "currentColor" gets substituted out for any colour we set.
//
// - We're using MUI SvgIcon which recommends a viewBox of "0 0 24 24" for best scaling with text size.
//   However we haven't always used "0 0 24 24" previously.

export interface IconProps {
    // eslint-disable-next-line react/require-default-props
    color?: string;
    // eslint-disable-next-line react/require-default-props
    fontSize?: string;
}

interface InternalIconProps extends IconProps {
    children: React.ReactNode;
}

const Icon: FC<InternalIconProps> = (props) => {
    const { color, fontSize, children } = props;
    return (
        <SvgIcon inheritViewBox sx={{ color, fontSize }}>
            {children}
        </SvgIcon>
    );
};

Icon.defaultProps = {
    color: 'grey600.main',
    fontSize: '1.5rem',
};

export const MerchantsIcon: FC<IconProps> = ({ color, fontSize }) => (
    <Icon color={color} fontSize={fontSize}><MerchantsSvg data-testid="MerchantsIcon" /></Icon>
);

export const ScannersIcon: FC<IconProps> = ({ color, fontSize }) => (
    <Icon color={color} fontSize={fontSize}><ScannersSvg /></Icon>
);

export const ScansIcon: FC<IconProps> = ({ color, fontSize }) => (
    <Icon color={color} fontSize={fontSize}><ScansSvg data-testid="ScansIcon" /></Icon>
);

export const AccountIcon: FC<IconProps> = ({ color, fontSize }) => (
    <Icon color={color} fontSize={fontSize}><AccountSvg /></Icon>
);

export const UsersIcon: FC<IconProps> = ({ color, fontSize }) => (
    <Icon color={color} fontSize={fontSize}><UsersSvg /></Icon>
);

export const ProductsIcon: FC<IconProps> = ({ color, fontSize }) => (
    <Icon color={color} fontSize={fontSize}><ProductsSvg /></Icon>
);

export const ImageIcon: FC<IconProps> = ({ color, fontSize }) => (
    <Icon color={color} fontSize={fontSize}><ImageSvg /></Icon>
);

export const TagIcon: FC<IconProps> = ({ color, fontSize }) => (
    <Icon color={color} fontSize={fontSize}><TagSvg /></Icon>
);

export const SpreadsheetIcon: FC<IconProps> = ({ color, fontSize }) => (
    <Icon color={color} fontSize={fontSize}><SpreadsheetSvg data-testid="SpreadsheetIcon" /></Icon>
);

export const DocumentIcon: FC<IconProps> = ({ color, fontSize }) => (
    <Icon color={color} fontSize={fontSize}><DocumentSvg /></Icon>
);

export const CameraIcon: FC<IconProps> = ({ color, fontSize }) => (
    <Icon color={color} fontSize={fontSize}><MovieCameraSvg /></Icon>
);

export const UserProfileIcon: FC<IconProps> = ({ color, fontSize }) => (
    <Icon color={color} fontSize={fontSize}><UserProfileSvg /></Icon>
);

export const LinkIcon: FC<IconProps> = ({ color, fontSize }) => (
    <Icon color={color} fontSize={fontSize}><LinkSvg /></Icon>
);

export const RestartIcon: FC<IconProps> = ({ color, fontSize }) => (
    <Icon color={color} fontSize={fontSize}><RestartSvg /></Icon>
);

export const ReportsIcon: FC<IconProps> = ({ color, fontSize }) => (
    <Icon color={color} fontSize={fontSize}><ReportsSvg /></Icon>
);

export const EditIcon: FC<IconProps> = ({ color, fontSize }) => (
    <Icon color={color} fontSize={fontSize}><EditSvg /></Icon>
);

export const MeasurementIcon: FC<IconProps> = ({ color, fontSize }) => (
    <Icon color={color} fontSize={fontSize}><MeasurementSvg /></Icon>
);

export const SizeMatchIcon: FC<IconProps> = ({ color, fontSize }) => (
    <Icon color={color} fontSize={fontSize}><SizeMatchSvg /></Icon>
);

export const SizeMatchRangeIcon: FC<IconProps> = ({ color, fontSize }) => (
    <Icon color={color} fontSize={fontSize}><SizeMatchRangeSvg /></Icon>
);

export const ArrowIcon: FC<IconProps> = ({ color, fontSize }) => (
    <Icon color={color} fontSize={fontSize}><ArrowSvg /></Icon>
);

export const BodyScanIcon: FC<IconProps> = ({ color, fontSize }) => (
    <Icon color={color} fontSize={fontSize}><BodyScanSvg /></Icon>
);

export const FootScanIcon: FC<IconProps> = ({ color, fontSize }) => (
    <Icon color={color} fontSize={fontSize}><FootScanSvg /></Icon>
);

export const WellnessIcon: FC<IconProps> = ({ color, fontSize }) => (
    <Icon color={color} fontSize={fontSize}><WellnessScanSvg /></Icon>
);

export const HandScanIcon: FC<IconProps> = ({ color, fontSize }) => (
    <Icon color={color} fontSize={fontSize}><HandScanSvg /></Icon>
);

export const HeadScanIcon: FC<IconProps> = ({ color, fontSize }) => (
    <Icon color={color} fontSize={fontSize}><HeadScanSvg /></Icon>
);

export const PuzzleIcon: FC<IconProps> = ({ color, fontSize }) => (
    <Icon color={color} fontSize={fontSize}><PuzzleSvg /></Icon>
);

export const ListIcon: FC<IconProps> = ({ color, fontSize }) => (
    <Icon color={color} fontSize={fontSize}><ListSvg /></Icon>
);

export const ScannerProfileIcon: FC<IconProps> = ({ color, fontSize }) => (
    <Icon color={color} fontSize={fontSize}><ScannerProfileSvg /></Icon>
);

export const PointerIcon: FC<IconProps> = ({ color, fontSize }) => (
    <Icon color={color} fontSize={fontSize}><PointerSvg /></Icon>
);

export const GridIcon: FC<IconProps> = ({ color, fontSize }) => (
    <Icon color={color} fontSize={fontSize}><GridSvg /></Icon>
);

export const AccessoriesGarmentIcon: FC<IconProps> = ({ color, fontSize }) => (
    <Icon color={color} fontSize={fontSize}><AccessoriesGarmentSvg data-testid="AccessoriesGarmentIcon" /></Icon>
);

export const BeltGarmentIcon: FC<IconProps> = ({ color, fontSize }) => (
    <Icon color={color} fontSize={fontSize}><BeltGarmentSvg /></Icon>
);

export const FootwearGarmentIcon: FC<IconProps> = ({ color, fontSize }) => (
    <Icon color={color} fontSize={fontSize}><FootwearGarmentSvg /></Icon>
);

export const FullbodyGarmentIcon: FC<IconProps> = ({ color, fontSize }) => (
    <Icon color={color} fontSize={fontSize}><FullbodyGarmentSvg /></Icon>
);

export const GlovesGarmentIcon: FC<IconProps> = ({ color, fontSize }) => (
    <Icon color={color} fontSize={fontSize}><GlovesGarmentSvg /></Icon>
);

export const HeadgearGarmentIcon: FC<IconProps> = ({ color, fontSize }) => (
    <Icon color={color} fontSize={fontSize}><HeadgearGarmentSvg /></Icon>
);

export const JumperGarmentIcon: FC<IconProps> = ({ color, fontSize }) => (
    <Icon color={color} fontSize={fontSize}><JumperGarmentSvg /></Icon>
);

export const OuterwearGarmentIcon: FC<IconProps> = ({ color, fontSize }) => (
    <Icon color={color} fontSize={fontSize}><OuterwearGarmentSvg /></Icon>
);

export const ShirtGarmentIcon: FC<IconProps> = ({ color, fontSize }) => (
    <Icon color={color} fontSize={fontSize}><ShirtGarmentSvg /></Icon>
);

export const ShortsGarmentIcon: FC<IconProps> = ({ color, fontSize }) => (
    <Icon color={color} fontSize={fontSize}><ShortsGarmentSvg /></Icon>
);

export const SkirtGarmentIcon: FC<IconProps> = ({ color, fontSize }) => (
    <Icon color={color} fontSize={fontSize}><SkirtGarmentSvg /></Icon>
);

export const TrousersGarmentIcon: FC<IconProps> = ({ color, fontSize }) => (
    <Icon color={color} fontSize={fontSize}><TrousersGarmentSvg /></Icon>
);

export const TshirtGarmentIcon: FC<IconProps> = ({ color, fontSize }) => (
    <Icon color={color} fontSize={fontSize}><TshirtGarmentSvg /></Icon>
);

export const OtherGarmentIcon: FC<IconProps> = ({ color, fontSize }) => (
    <Icon color={color} fontSize={fontSize}><OtherGarmentSvg /></Icon>
);

export const AddFitVariantIcon: FC<IconProps> = ({ color, fontSize }) => (
    <Icon color={color} fontSize={fontSize}><AddFitVariantSvg /></Icon>
);

export const HelpIcon: FC<IconProps> = ({ color, fontSize }) => (
    <Icon color={color} fontSize={fontSize}><HelpSvg /></Icon>
);

export const GlobeIcon: FC<IconProps> = ({ color, fontSize }) => (
    <Icon color={color} fontSize={fontSize}><GlobeSvg /></Icon>
);

export const ImpersonateIcon: FC<IconProps> = ({ color, fontSize }) => (
    <Icon color={color} fontSize={fontSize}><ImpersonateSvg /></Icon>
);

export const LogoutIcon: FC<IconProps> = ({ color, fontSize }) => (
    <Icon color={color} fontSize={fontSize}><LogoutSvg /></Icon>
);

export const AccessGroupIcon: FC<IconProps> = ({ color, fontSize }) => (
    <Icon color={color} fontSize={fontSize}><AccessGroupSvg /></Icon>
);

export const SizesIcon: FC<IconProps> = ({ color, fontSize }) => (
    <Icon color={color} fontSize={fontSize}><SizesSvg /></Icon>
);

export const BoddIcon: FC<IconProps> = ({ color, fontSize }) => (
    <Icon color={color} fontSize={fontSize}><BoddSvg /></Icon>
);

export const ScannerProfilesDiagramIcon: FC<IconProps> = () => (
    <SvgIcon fontSize="inherit" viewBox="0 0 163 172"><ScannerProfilesDiagramSvg /></SvgIcon>
);
