/**
 * Log a warning and show a toast!
 */
import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import SnackbarUtils from '../features/common/snackbar-utils';
// eslint-disable-next-line import/prefer-default-export

const tryParseIfString = (payload: any) => {
    if (typeof payload === 'string') {
        try {
            const parsed = JSON.parse(payload);
            return parsed;
        } catch (e) {
            // we couldn't parse what looked like a string so maybe it wasn't json???   error will be logged by handler.
        }
    }
    return payload;
};

const getToastMessage = (action: any) => {
    const details = action?.payload?.data?.detail ?? tryParseIfString(action?.payload?.data) ?? 'An error occured';
    let msg = details;
    // map messages for common error codes
    if (action?.payload?.status === 403) {
        return 'Access Denied';
    }
    if (action?.payload?.status === 404) {
        msg = 'Not Found';
        if (action?.meta?.baseQueryMeta?.qryOptions?.skip404Toast) {
            msg = '';
        }
        return msg;
    }
    if (action?.payload?.status === 'PARSING_ERROR') {
        return 'An error occured reading the response from the server.  \r\nPlease try again';
    }

    // if the error has a title, that's a good place to start
    if (details.title) {
        msg = details.title;
    }
    // override for validation errors that contain a collection of issues
    if (details.errors) {
        msg = Object.keys(details.errors).reduce((a, c) => {
            if (a) {
                return `${a}\r\n${details.errors[c].join('\r\n')}`;
            }

            return details.errors[c].join('\r\n');
        }, '');
    }

    // if we have validation instructions, the client will handle the issue without need for a toast
    let hasValidationInstructions: boolean;
    try {
        hasValidationInstructions = JSON.parse(action?.payload?.data)?.hasValidationInstructions;
    } catch {
        hasValidationInstructions = false;
    }

    if (hasValidationInstructions) {
        return '';
    }

    return msg;
};

const getErrorToLog = (action: any) => {
    if (action?.payload?.status === 403) {
        return new Error('Access Denied');
    }
    if (action?.payload?.status === 404) {
        if (action?.meta?.baseQueryMeta?.qryOptions?.skip404Toast) {
            return null; // we don't want to log these errors
        }
        return new Error('Not Found');
    }
    if (action?.payload?.status === 'PARSING_ERROR') {
        // at the moment, these are mostly cloudflare 520s that we are trying to learn about
        const { headers } = action.meta.baseQueryMeta.response;
        let errorMsg = `Error parsing response from: ${action.meta.baseQueryMeta.request.method} ${action.meta.baseQueryMeta.request.url}`;
        if (headers.has('cf-ray')) {
            errorMsg += `  RayId: ${headers.get('cf-ray')}`;
        }

        return new Error(errorMsg);
    }

    // if the error has a title, that's a good place to start
    const details = action?.payload?.data?.detail ?? action?.payload?.data ?? 'An error occured';
    let msg = details;
    if (details.title) {
        msg = details.title;
    }
    // override for validation errors that contain a collection of issues
    if (details.errors) {
        msg = Object.keys(details.errors).reduce((a, c) => {
            if (a) {
                return `${a}\r\n${details.errors[c].join('\r\n')}`;
            }

            return details.errors[c].join('\r\n');
        }, '');
    }
    return msg;
};

const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
        const errorToLog = getErrorToLog(action);
        const userMessage = getToastMessage(action);

        if (errorToLog) {
            Sentry.captureException(errorToLog);
        }

        if (userMessage && SnackbarUtils.isErrorToastEnabled()) {
            SnackbarUtils.error(userMessage);
        }

        // eslint-disable-next-line no-console
        console.warn({ msg: userMessage, error: errorToLog });
    }

    return next(action);
};

export default rtkQueryErrorLogger;
