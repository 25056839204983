import { createContext } from 'react';
import { PortalUser } from '../authSlice';

export interface BoddAuthState {
    login: (opts?: { appState: { returnTo: string } }) => Promise<void>;
    loginSilent: () => Promise<void>;
    logout: () => void;
    isLoading: boolean;
    isAuthenticated: boolean;
    error: Error | undefined;
    user: PortalUser | null;
}

const stub = (): never => {
    throw new Error('You forgot to wrap your component in <Auth0Provider>.');
};

const initialAuthState = ({
    isAuthenticated: false,
    isLoading: true,
    login: stub,
    logout: stub,
    error: undefined,
    user: null,
    loginSilent: stub,
});

export const BoddPortalAuthContext = createContext<BoddAuthState>(initialAuthState);
