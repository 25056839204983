import React, { FC } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styles from './ModalDialogComponent.module.scss';

interface ModalDialogComponentProps {
    title: string;
    confirmText?: string;
    open: boolean;
    onConfirm?: () => void;
    onCancel?: () => void;
    setOpen: (open: boolean) => void;
    confirmEnabled?: boolean;
    maxWidth?: string | null;
    onBack?: () => void;
    confirmColor?: 'primary' | 'inherit' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
    customActions?: React.ReactNode;
    children: React.ReactNode;
}

const ModalDialogComponent: FC<ModalDialogComponentProps> = (props) => {
    const {
        title,
        confirmText,
        open,
        onConfirm,
        onCancel,
        setOpen,
        children,
        confirmEnabled,
        maxWidth,
        onBack,
        confirmColor,
        customActions,
    } = props;

    const cancelDialog = () => {
        if (onCancel) {
            onCancel();
        }
        setOpen(false);
    };

    return (
        <div
            className={styles.ModalDialogComponent}
            data-testid="ModalDialogComponent"
        >
            <Dialog
                open={open}
                fullWidth
                maxWidth={maxWidth === null ? 'xs' : undefined}
                PaperProps={{ sx: { maxWidth } }}
            >
                <DialogTitle>
                    <Box display="flex" alignItems="center">
                        <Box flexGrow={1}>{title}</Box>
                        <Box>
                            <IconButton edge="end" onClick={() => cancelDialog()}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent dividers>
                    {children}
                </DialogContent>
                {(onConfirm || onBack || customActions !== undefined) && (
                    <DialogActions>
                        {customActions}
                        {onBack && (
                            <Button
                                onClick={() => {
                                    onBack();
                                }}
                                variant="outlined"
                            >
                                Back
                            </Button>
                        )}
                        {onConfirm && (
                            <Button
                                color={confirmColor}
                                disabled={!confirmEnabled}
                                onClick={() => {
                                    onConfirm();
                                }}
                            >
                                {confirmText}
                            </Button>
                        )}

                    </DialogActions>
                )}
            </Dialog>
        </div>
    );
};

ModalDialogComponent.defaultProps = {
    confirmText: 'Confirm',
    confirmEnabled: true,
    onConfirm: undefined,
    onCancel: undefined,
    maxWidth: null,
    onBack: undefined,
    confirmColor: 'primary',
    customActions: undefined,
};

export default ModalDialogComponent;
