import React, { FC, SyntheticEvent, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { Box, CircularProgress, MenuItem, TextField, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ModalDialogComponent from '../ModalDialogComponent/ModalDialogComponent';
import { impersonateMerchantStart, impersonateMerchantStop, selectAllMerchants } from '../../auth/authSlice';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { MerchantSummaryDto } from '../../../../services';
import ListItemLink from '../ListItemLink/ListItemLink';
import { theme } from '../../theme/theme';
import { ImpersonateIcon } from '../IconSelectorComponent/IconSelectorComponent';
import BoddOnlyComponent from '../BoddOnlyComponent/BoddOnlyComponent';

interface ImpersonateMerchantDialogComponentProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    onCancel: () => void;
}

const ImpersonateMerchantDialogComponent: FC<ImpersonateMerchantDialogComponentProps> = (props) => {
    const { open, setOpen, onCancel } = props;
    const [searchMerchantNameConfirmEnabled, setSearchMerchantNameConfirmEnabled] = useState<boolean>(false);
    const [impersonatedMerchant, setImpersonatedMerchant] = useState<MerchantSummaryDto | null>(null);
    const [merchantListOpen, setMerchantListOpen] = useState(false);
    const loading = false;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const merchants = useAppSelector(selectAllMerchants);

    const handleSearchMerchantNameInputChange = (event: SyntheticEvent, value?: string) => {
        const merchantNames = merchants.map((merchant) => merchant.name);
        if (value !== null && merchantNames.includes(value!)) {
            setSearchMerchantNameConfirmEnabled(true);
            setImpersonatedMerchant(merchants.find((item) => item.name === value)!);
        } else {
            setSearchMerchantNameConfirmEnabled(false);
            setImpersonatedMerchant(null);
        }
    };

    const handleImpersonateMerchant = () => {
        dispatch(impersonateMerchantStart({
            merchant: impersonatedMerchant!,
        }));
        setOpen(false);
        navigate('/');
    };

    return (
        <ModalDialogComponent
            data-testid="ImpersonateMerchantDialogComponent"
            title="Impersonate merchant"
            confirmText="Impersonate"
            open={open}
            setOpen={setOpen}
            onConfirm={() => handleImpersonateMerchant()}
            confirmEnabled={searchMerchantNameConfirmEnabled}
            onCancel={onCancel}
        >
            <Autocomplete
                freeSolo
                id="search-merchant-name"
                disableClearable
                fullWidth
                open={merchantListOpen}
                onOpen={() => {
                    setMerchantListOpen(true);
                }}
                onClose={() => {
                    setMerchantListOpen(false);
                }}
                options={merchants.map((merchant) => merchant.name)}
                onInputChange={handleSearchMerchantNameInputChange}
                loading={loading}
                renderInput={(params) => (
                    <TextField
                        /* eslint-disable-next-line react/jsx-props-no-spreading */
                        {...params}
                        sx={{ my: 2, mx: 0 }}
                        label="Search merchant name"
                        InputProps={{
                            ...params.InputProps,
                            type: 'search',
                            endAdornment: (
                                <>
                                    {loading
                                        ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </>
                            ),
                        }}
                    />
                )}
            />

        </ModalDialogComponent>
    );
};

interface ImpersonateMerchantMenuItemProps {
    isBoddUser: boolean;
    merchantId: number | undefined;
    closeMenu: () => void;
}

const ImpersonateMerchantMenuItem: FC<ImpersonateMerchantMenuItemProps> = (props) => {
    const { isBoddUser, merchantId, closeMenu } = props;
    const [showImpersonateMerchantDialog, setShowImpersonateMerchantDialog] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isMobileLayout = useMediaQuery(theme.breakpoints.down('md'));

    const handleImpersonateMerchantMenuClick = () => {
        // closeMenu();
        setShowImpersonateMerchantDialog(true);
    };

    const handleStopImpersonation = () => {
        dispatch(impersonateMerchantStop());
        closeMenu();
        navigate('/');
    };

    return (
        <div data-testid="ImpersonateMerchantMenuItem">
            {isMobileLayout ? (
                <Box>
                    {!merchantId && (
                        <BoddOnlyComponent>
                            <ListItemLink
                                icon={<ImpersonateIcon />}
                                text="Impersonate Merchant"
                                onClick={handleImpersonateMerchantMenuClick}
                            />
                        </BoddOnlyComponent>
                    )}
                    {isBoddUser && merchantId && (
                        <ListItemLink
                            icon={<ImpersonateIcon />}
                            text="Stop Impersonation"
                            onClick={() => handleStopImpersonation()}
                        />
                    )}
                </Box>
            ) : (
                <Box>
                    {!merchantId && (
                        <BoddOnlyComponent>
                            <MenuItem
                                onClick={handleImpersonateMerchantMenuClick}
                            >
                                Impersonate Merchant
                            </MenuItem>
                        </BoddOnlyComponent>
                    )}
                    {isBoddUser && merchantId
                        && (
                            <MenuItem
                                onClick={handleStopImpersonation}
                            >
                                Stop Impersonation
                            </MenuItem>
                        )}
                </Box>
            )}

            <ImpersonateMerchantDialogComponent
                open={showImpersonateMerchantDialog}
                setOpen={setShowImpersonateMerchantDialog}
                onCancel={closeMenu}
            />
        </div>
    );
};

export default ImpersonateMerchantMenuItem;
