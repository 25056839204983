// TODO: fix these cyclic issues
import {
    Action,
    combineReducers,
    configureStore,
    createListenerMiddleware,
    Middleware,
    Reducer,
    ThunkAction,
} from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import authReducer from '../features/common/auth/authSlice';
// eslint-disable-next-line import/no-cycle
import merchantsReducer from '../features/merchants/merchantsSlice';
// eslint-disable-next-line import/no-cycle
import scansReducer from '../features/scans/scanSlice';
// eslint-disable-next-line import/no-cycle
import productsReducer from '../features/products/productsSlice';
// eslint-disable-next-line import/no-cycle
import inventoryReducer from '../features/inventory/inventorySlice';
import { inventoryApi } from '../services/inventoryApi';

import { merchantAdminApi } from '../services/merchantAdminApi';
import { scanApi } from '../services/scanApi';
import rtkQueryErrorLogger from './rtk-query-error-logger';

const staticReducers = {
    auth: authReducer,
    merchants: merchantsReducer,
    scans: scansReducer,
    products: productsReducer,
    inventory: inventoryReducer,
    [inventoryApi.reducerPath]: inventoryApi.reducer,
    [merchantAdminApi.reducerPath]: merchantAdminApi.reducer,
    [scanApi.reducerPath]: scanApi.reducer,
};

const regionListenerMiddleware = createListenerMiddleware<RootState>();
regionListenerMiddleware.startListening({
    predicate: (action: any, currentState: { auth: { currentRegion: any; }; }, previousState: {
        auth: { currentRegion: any; };
    }) => currentState.auth.currentRegion !== previousState.auth.currentRegion,
    effect: async (action: any, listenerApi: {
        dispatch: (arg0: any) => void;
    }) => {
        listenerApi.dispatch(scanApi.util.invalidateTags(['Scans']));
    },
});

const middlewares: Middleware[] = [
    regionListenerMiddleware.middleware,
    rtkQueryErrorLogger,
    inventoryApi.middleware,
    merchantAdminApi.middleware,
    scanApi.middleware,
];

export const store = configureStore({
    reducer: {
        ...staticReducers,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware()
        .concat(middlewares),
});

export type AppStore = typeof store;

// TODO: ...will need to add reducer code splitting later...
const asyncReducers: { [key: string]: Reducer } = {};

// eslint-disable-next-line no-unused-vars
export const registerReducer: (name: string, reducer: Reducer) => AppStore = (name, reducer) => {
    if (!asyncReducers[name]) {
        asyncReducers[name] = reducer;
        store.replaceReducer(combineReducers({ ...staticReducers, ...asyncReducers }));
    }
    return store;
};

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
    RootState,
    unknown,
    Action<string>>;
