export enum Measure {cm = 'cm', mm = 'mm', inches = 'inches'}

export default class MeasureUtils {
    static getMeasurementScale = (measure: Measure) => {
        if (measure === Measure.inches) {
            return 0.0393701;
        }
        if (measure === Measure.cm) {
            return 0.1;
        }
        return 1;
    };

    static getDecimalPlaces = (measure: Measure) => {
        if (measure === Measure.inches) {
            return 2;
        }
        if (measure === Measure.cm) {
            return 1;
        }
        return 0;
    };
}
