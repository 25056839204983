// noinspection TypeScriptValidateJSTypes

import * as scanApiModule from './scanApi';
import { ScanType, SessionDto } from './scanApi';

const { scanApi } = scanApiModule;

export const scanTypesMap: { [key in ScanType]: string } = {
    'BodyScan': 'Body Scan',
    'FootScan': 'Foot Scan',
    'WellnessScan': 'Wellness Scan',
    'HandScan': 'Hand Scan',
    'HeadScan': 'Head Scan',
    'HealthScan': 'Deprecated -  no one should see this.',

}

export const getScanIdMap = (scanSession: SessionDto) => {
    const body = scanSession.scans?.find((s) => s.scanType === 'BodyScan') ?? null;
    const head = scanSession.scans?.find((s) => s.scanType === 'HeadScan') ?? null;
    const feet = scanSession.scans?.find((s) => s.scanType === 'FootScan') ?? null;
    const hand = scanSession.scans?.find((s) => s.scanType === 'HandScan') ?? null;
    const wellness = scanSession.scans?.find((s) => s.scanType === 'WellnessScan') ?? null;
    const deprecated = scanSession.scans?.find((s) => s.scanType === 'HealthScan') ?? null;

    return {
        ...(body ? { BodyScan: body.id } : {}),
        ...(head ? { HeadScan: head.id } : {}),
        ...(feet ? { FootScan: feet.id } : {}),
        ...(hand ? { HandScan: hand.id } : {}),
        ...(wellness ? { WellnessScan: wellness.id } : {}),
        ...(deprecated ? { HealthScan: deprecated.id } : {}),
    };
};

export const enhancedScanApi = scanApi.enhanceEndpoints({
    addTagTypes: ["Scans", "SizeMatchResults"],
    endpoints: {
        sizeMatchControllerRetrieveSizeMatchRecommendationsCsv: {
            providesTags: ["Scans"],
            query: (queryArg) => ({
                url: `/size-match/${queryArg.merchantId}/scans/${queryArg.sessionId}/csv`,
                responseHandler: (response: { text: () => any; }) => response.text(),
                params: {
                    productCode: queryArg.productCode,
                    productName: queryArg.productName,
                    fit: queryArg.fit,
                    categoryTags: queryArg.categoryTags,
                },
            }),
        },
        sizeMatchControllerRetrieveSizeMatchRecommendationsBulkCsv: {
            query: (queryArg) => ({
                url: `/size-match/bulk-csv`,
                responseHandler: (response: { text: () => any; }) => response.text(),
                params: {
                    MerchantId: queryArg.merchantId,
                    ScanTitle: queryArg.scanTitle,
                    GarmentType: queryArg.garmentType,
                    from: queryArg.from,
                    to: queryArg.to,
                    Gender: queryArg.gender,
                    CategoryTags: queryArg.categoryTags,
                },
            }),
        },
        sizeMatchControllerRetrieveSizeMatchRecommendations: {
            providesTags: ["SizeMatchResults"],
        },
        sizeMatchControllerRetrieveScanSizeMatchRecommendations: {
            providesTags: ["SizeMatchResults"],
        },
        sizeMatchControllerDeleteSizeMatchResult: {
            invalidatesTags: ["SizeMatchResults"],
        },
        reportsControllerRetrieveScanQualityReport: {
            query: (queryArg) => ({
                url: `/reports/scan-quality-report`,
                params: {
                    From: queryArg["from"],
                    To: queryArg.to,
                    MerchantId: queryArg.merchantId,
                },
                responseHandler: (response: { text: () => any; }) => response.text(),
            }),
        },
        reportsControllerRetrieveMeasurementQualityReport: {
            query: (queryArg) => ({
                url: `/reports/measurement-quality-report`,
                params: {
                    From: queryArg["from"],
                    To: queryArg.to,
                    MerchantId: queryArg.merchantId,
                },
                responseHandler: (response: { text: () => any; }) => response.text(),
            }),
        },
        reportsControllerRetrieveSizeMatchAccuracyReport: {
            query: (queryArg) => ({
                url: `/reports/sizematch-accuracy-report`,
                params: {
                    From: queryArg["from"],
                    To: queryArg.to,
                    MerchantId: queryArg.merchantId,
                },
                responseHandler: (response: { text: () => any; }) => response.text(),
            }),
        },
        sessionsControllerRetrieveSessions: { providesTags: ["Scans"] },
        scanControllerRetrieveScan: { providesTags: ["Scans"] },
        scanControllerSubmitQualityReport: { invalidatesTags: ["Scans"] },
        measurementsControllerRetrieveMeasurementsCsv: {
            query: (queryArg) => ({
                url: `/scans/${queryArg.sessionId}/csv`,
                responseHandler: (response: { text: () => any; }) => response.text(),
            }),
        },
    },
});

export * from './scanApi';

export const {
    useMeasurementsControllerRetrieveMeasurementAmendmentsQuery,
    useMeasurementsControllerUpdateScanMeasurementMutation,
    useMeasurementsControllerRetrieveScanMeasurementsQuery,
    useSizeMatchControllerAddOrUpdateSizeMatchReportMutation,
    useScanControllerRequestIntegrationMutation,
    useSessionsControllerRetrieveSessionsQuery,
    useSessionsControllerRetrieveSessionQuery,
    useSizeMatchControllerRetrieveScanSizeMatchRecommendationsQuery,
    useSizeMatchControllerRetrieveSizeMatchRecommendationsCsvQuery,
    useScanControllerSubmitQualityReportMutation,
    useLazyMeasurementsControllerRetrieveMeasurementAmendmentsQuery,
    useLazyScanControllerRetrieveScanQuery,
    useLazyScanControllerRetrieveDownloadUrlQuery,
    useLazySessionsControllerRetrieveDiagnosticsUrlQuery,
    useLazyScanControllerRetrieveScanSessionTitleQuery,
    useLazyMeasurementsControllerRetrieveScanMeasurementsQuery,
    useLazyMeasurementsControllerRetrieveMeasurementsCsvQuery,
    useLazyMeasurementsControllerRetrieveAllMeasurementsQuery,
    useLazySizeMatchControllerRetrieveSizeMatchRecommendationsCsvQuery,
    useLazySizeMatchControllerRetrieveSizeMatchRecommendationsBulkCsvQuery,
    useLazyReportsControllerRetrieveScanQualityReportQuery,
    useLazyReportsControllerRetrieveMeasurementQualityReportQuery,
    useLazySessionsControllerRetrieveSessionStatusQuery,
    useLazyReportsControllerRetrieveSizeMatchAccuracyReportQuery,
    useLazySizeMatchControllerRetrieveScanSizeMatchRecommendationsQuery,
    useLazySizeMatchControllerRetrieveProductSizeMatchRecommendationsQuery,
} = enhancedScanApi;
