// noinspection TypeScriptValidateJSTypes

import { inventoryApi } from './inventoryApi';

export const enhancedInventoryApi = inventoryApi.enhanceEndpoints({
    addTagTypes: ["Scanner", "ConfigurationProfile"],
    endpoints: {
        scannersControllerRetrieveScanners: { providesTags: ["Scanner"] },
        scannersControllerRetrieveScanner: { providesTags: ["Scanner"] },
        configurationProfilesControllerRetrieveScannerProfiles: { providesTags: ["Scanner", "ConfigurationProfile"] },
        configurationProfilesControllerRetrieveScannerProfile: { providesTags: ["Scanner", "ConfigurationProfile"] },
        configurationProfilesControllerUpdateScannerProfile: { invalidatesTags: ["ConfigurationProfile"] },
        configurationProfilesControllerUpdateScannerProfileFeatureOverrides: { invalidatesTags: ["ConfigurationProfile"] },
        scannersControllerImportScanners: { invalidatesTags: ["Scanner"] },
        scannersControllerApproveQualityControl: { invalidatesTags: ["Scanner"] },
        scannersControllerUpdateScannerConfiguration: { invalidatesTags: ["Scanner"] },
        scannersControllerDeclineQualityControl: { invalidatesTags: ["Scanner"] },
        scannersControllerUnlinkScanner: { invalidatesTags: ["Scanner"] },
        scannersControllerCancelActivation: { invalidatesTags: ["Scanner"] },
        scannersControllerRetrieveLinkRequest: {
            extraOptions: { qryOptions: { skip404Toast: true } },
        },
    },
});

export const {
    useScannersControllerRetrieveScannerQuery,
    useScannersControllerRetrieveScannersQuery,
    useScannersControllerImportScannersMutation,
    useScannersControllerCreateLinkRequestMutation,
    useScannersControllerRetrieveLinkRequestQuery,
    useScannersControllerApproveQualityControlMutation,
    useLazyScannersControllerRetrieveScannerQuery,
    useConfigurationProfilesControllerUpdateScannerProfileMutation,
} = enhancedInventoryApi;
