import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MerchantDto } from '../../services/merchantAdminApi';
// eslint-disable-next-line import/no-cycle
import { RootState } from '../../app/store';

export interface MerchantsState {
    merchantsList: MerchantDto[];
    merchantsPage: number;
    addingNew: boolean;
    newMerchant: MerchantDto;
    addingNewMerchantUser: boolean;
}

const emptyMerchant = { region: 'AU' } as MerchantDto;

const initialState: MerchantsState = {
    merchantsList: [],
    merchantsPage: 1,
    addingNew: false,
    newMerchant: emptyMerchant,
    addingNewMerchantUser: false,
};

export const merchantsSlice = createSlice({
    name: 'merchants',
    initialState,
    reducers: {
        startCreateMerchant: (state) => {
            state.addingNew = true;
        },
        cancelCreateMerchant: (state) => {
            state.addingNew = false;
            state.newMerchant = { ...emptyMerchant };
        },
        changeMerchantListPage: (state, action: PayloadAction<{ newPage: number }>) => {
            state.merchantsPage = action.payload.newPage;
        },
        createNewMerchantUser: (state) => {
            state.addingNewMerchantUser = true;
        },
        finishedCreatingNewMerchantUser: (state) => {
            state.addingNewMerchantUser = false;
        },

    },
});

export const selectMerchantsState = (state: RootState) => state.merchants;

export const selectNewMerchantState = (state: RootState) => state.merchants.newMerchant;

export const {
    startCreateMerchant,
    cancelCreateMerchant,
    changeMerchantListPage,
    createNewMerchantUser,
    finishedCreatingNewMerchantUser,
} = merchantsSlice.actions;

export default merchantsSlice.reducer;
