import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { RootState } from '../../app/store';
import { ScannerStatus } from '../../services/inventoryApi';

export interface InventoryState {

}

const initialState: InventoryState = {};

export const inventorySlice = createSlice({
    name: 'inventorySlice',
    initialState,
    reducers: {},
});

export const allScannerStatuses: { [key in ScannerStatus]: string } = {
    AwaitRegistration: 'Awaiting Registration',
    AwaitDiagnostics: 'Awaiting Diagnostics',
    AwaitQualityControlScanApproval: 'Awaiting QC',
    AwaitCustomerQualityControlScan: 'Awaiting Customer QC',
    QualityControlScanRejected: 'QC Rejected',
    Inactive: 'Inactive',
    Ready: 'Ready',
    RequiresCredentials: 'Requires Credentials',
};

export const selectInventoryState = (state: RootState) => state.inventory;

// eslint-disable-next-line no-empty-pattern
export const {} = inventorySlice.actions;

export default inventorySlice.reducer;
