import React, { FC, useState } from 'react';
import { Box, Button, ButtonGroup, MenuItem, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ModalDialogComponent from '../ModalDialogComponent/ModalDialogComponent';
import { changeRegion, selectCurrentMerchant, selectCurrentRegion } from '../../auth/authSlice';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { BoddRegion } from '../../../../services';
import styles from './RegionSelectorMenuItem.module.scss';
import ListItemLink from '../ListItemLink/ListItemLink';
import { theme } from '../../theme/theme';
import { GlobeIcon } from '../IconSelectorComponent/IconSelectorComponent';

interface RegionSwitchComponentProps {
    currentRegion: BoddRegion;
    regionUpdated: (newRegion: BoddRegion) => void;
    enabled: boolean;
}

const RegionSwitch: FC<RegionSwitchComponentProps> = (props) => {
    const { currentRegion, regionUpdated, enabled } = props;

    return (
        <div className={styles.RegionSwitch} data-testid="MeasureSwitchComponent">
            <ButtonGroup sx={{ my: 2, mx: 0 }}>
                <Button
                    disabled={!enabled}
                    className={currentRegion === 'AU' ? styles.ActiveButton : styles.InactiveButton}
                    onClick={() => regionUpdated('AU')}
                    variant={currentRegion === 'AU' ? 'contained' : 'text'}
                >
                    AU
                </Button>
                <Button
                    disabled={!enabled}
                    className={currentRegion === 'US' ? styles.ActiveButton : styles.InactiveButton}
                    onClick={() => regionUpdated('US')}
                    variant={currentRegion === 'US' ? 'contained' : 'text'}
                >
                    US
                </Button>
            </ButtonGroup>
        </div>
    );
};

interface RegionSelectorDialogComponentProps {
    onClose: () => void;
    dialogOpen: boolean;
    setDialogOpen: (open: boolean) => void;
}

const RegionSelectorDialogComponent: FC<RegionSelectorDialogComponentProps> = (props) => {
    const { dialogOpen, setDialogOpen, onClose } = props;
    const currentMerchant = useAppSelector(selectCurrentMerchant);
    const initialRegion = useAppSelector(selectCurrentRegion);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [region, setRegion] = useState<BoddRegion>(initialRegion);

    const cancel = () => {
        setDialogOpen(false);
        onClose();
    };

    const switchRegion = () => {
        dispatch(changeRegion(region));
        setDialogOpen(false);
        onClose();
        navigate('/');
    };

    return (
        <ModalDialogComponent
            data-testid="RegionSelectorDialogComponent"
            title="Select region "
            confirmText="Apply"
            open={dialogOpen}
            setOpen={setDialogOpen}
            onCancel={() => cancel()}
            onConfirm={() => switchRegion()}
            confirmEnabled={initialRegion !== region}
        >
            <RegionSwitch currentRegion={region} regionUpdated={(r) => setRegion(r)} enabled={!currentMerchant} />

        </ModalDialogComponent>
    );
};

interface RegionSelectorMenuItemProps {
    onClose: () => void;
}

const RegionSelectorMenuItem: FC<RegionSelectorMenuItemProps> = (props) => {
    const { onClose } = props;
    const currentRegion = useAppSelector(selectCurrentRegion);
    const currentMerchant = useAppSelector(selectCurrentMerchant);
    const isMobileLayout = useMediaQuery(theme.breakpoints.down('md'));

    const [dialogOpen, setDialogOpen] = useState(false);

    return (
        <>
            {isMobileLayout ? (
                <Box>
                    <ListItemLink icon={<GlobeIcon />} text={`Region: ${currentRegion}`} onClick={() => setDialogOpen(true)} />
                </Box>
            ) : (
                <Box>
                    <MenuItem onClick={() => setDialogOpen(true)} disabled={!!currentMerchant}>
                        Region:
                        {' '}
                        {currentRegion}
                    </MenuItem>
                </Box>
            )}

            <RegionSelectorDialogComponent onClose={onClose} dialogOpen={dialogOpen} setDialogOpen={setDialogOpen} />
        </>
    );
};

export default RegionSelectorMenuItem;
