import { inventorySplitApi as api } from "./apiBase";
const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        configurationProfilesControllerRetrieveScannerProfiles: build.query<
            ConfigurationProfilesControllerRetrieveScannerProfilesApiResponse,
            ConfigurationProfilesControllerRetrieveScannerProfilesApiArg
        >({
            query: (queryArg) => ({
                url: `/config-profiles`,
                params: {
                    merchantId: queryArg.merchantId,
                    page: queryArg.page,
                },
            }),
        }),
        configurationProfilesControllerGetDefaultScannerProfile: build.query<
            ConfigurationProfilesControllerGetDefaultScannerProfileApiResponse,
            ConfigurationProfilesControllerGetDefaultScannerProfileApiArg
        >({
            query: (queryArg) => ({
                url: `/config-profiles/${queryArg.merchantId}/default`,
            }),
        }),
        configurationProfilesControllerRetrieveScannerProfile: build.query<
            ConfigurationProfilesControllerRetrieveScannerProfileApiResponse,
            ConfigurationProfilesControllerRetrieveScannerProfileApiArg
        >({
            query: (queryArg) => ({
                url: `/config-profiles/${queryArg.profileId}`,
            }),
        }),
        configurationProfilesControllerUpdateScannerProfile: build.mutation<
            ConfigurationProfilesControllerUpdateScannerProfileApiResponse,
            ConfigurationProfilesControllerUpdateScannerProfileApiArg
        >({
            query: (queryArg) => ({
                url: `/config-profiles/${queryArg.profileId}`,
                method: "PUT",
                body: queryArg.configurationProfileDto,
            }),
        }),
        configurationProfilesControllerDeleteScannerProfile: build.mutation<
            ConfigurationProfilesControllerDeleteScannerProfileApiResponse,
            ConfigurationProfilesControllerDeleteScannerProfileApiArg
        >({
            query: (queryArg) => ({
                url: `/config-profiles/${queryArg.profileId}`,
                method: "DELETE",
            }),
        }),
        configurationProfilesControllerUpdateScannerProfileFeatureOverrides:
            build.mutation<
                ConfigurationProfilesControllerUpdateScannerProfileFeatureOverridesApiResponse,
                ConfigurationProfilesControllerUpdateScannerProfileFeatureOverridesApiArg
            >({
                query: (queryArg) => ({
                    url: `/config-profiles/${queryArg.profileId}/features`,
                    method: "PUT",
                    body: queryArg.body,
                }),
            }),
        scannersControllerUpdateScannerConfiguration: build.mutation<
            ScannersControllerUpdateScannerConfigurationApiResponse,
            ScannersControllerUpdateScannerConfigurationApiArg
        >({
            query: (queryArg) => ({
                url: `/scanners/${queryArg.deviceId}/configuration`,
                method: "PUT",
                body: queryArg.scannerSettingsDto,
            }),
        }),
        devicesControllerCreateAwsActivation: build.mutation<
            DevicesControllerCreateAwsActivationApiResponse,
            DevicesControllerCreateAwsActivationApiArg
        >({
            query: (queryArg) => ({
                url: `/scanners/${queryArg.deviceId}/re-activate`,
                method: "POST",
            }),
        }),
        devicesControllerConfirmAwsActivation: build.mutation<
            DevicesControllerConfirmAwsActivationApiResponse,
            DevicesControllerConfirmAwsActivationApiArg
        >({
            query: (queryArg) => ({
                url: `/scanners/${queryArg.deviceId}/confirm-activation`,
                method: "POST",
                params: { activationId: queryArg.activationId },
            }),
        }),
        scannersControllerRetrieveScanner: build.query<
            ScannersControllerRetrieveScannerApiResponse,
            ScannersControllerRetrieveScannerApiArg
        >({
            query: (queryArg) => ({ url: `/scanners/${queryArg.deviceId}` }),
        }),
        scannersControllerRetrieveScanners: build.query<
            ScannersControllerRetrieveScannersApiResponse,
            ScannersControllerRetrieveScannersApiArg
        >({
            query: (queryArg) => ({
                url: `/scanners`,
                params: {
                    PageNumber: queryArg.pageNumber,
                    PageSize: queryArg.pageSize,
                    DeviceId: queryArg.deviceId,
                    MerchantId: queryArg.merchantId,
                    ConfigurationProfileId: queryArg.configurationProfileId,
                    ScannerStatus: queryArg.scannerStatus,
                },
            }),
        }),
        scannersControllerImportScanners: build.mutation<
            ScannersControllerImportScannersApiResponse,
            ScannersControllerImportScannersApiArg
        >({
            query: (queryArg) => ({
                url: `/scanners`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        scannersControllerCreateLinkRequest: build.mutation<
            ScannersControllerCreateLinkRequestApiResponse,
            ScannersControllerCreateLinkRequestApiArg
        >({
            query: (queryArg) => ({
                url: `/scanners/link-requests`,
                method: "POST",
                params: {
                    region: queryArg.region,
                    merchantId: queryArg.merchantId,
                },
            }),
        }),
        scannersControllerRetrieveLinkRequest: build.query<
            ScannersControllerRetrieveLinkRequestApiResponse,
            ScannersControllerRetrieveLinkRequestApiArg
        >({
            query: (queryArg) => ({
                url: `/scanners/link-requests/${queryArg.merchantId}/${queryArg.code}`,
            }),
        }),
        scannersControllerUnlinkScanner: build.mutation<
            ScannersControllerUnlinkScannerApiResponse,
            ScannersControllerUnlinkScannerApiArg
        >({
            query: (queryArg) => ({
                url: `/scanners/${queryArg.deviceId}/unlink`,
                method: "POST",
            }),
        }),
        scannersControllerApproveQualityControl: build.mutation<
            ScannersControllerApproveQualityControlApiResponse,
            ScannersControllerApproveQualityControlApiArg
        >({
            query: (queryArg) => ({
                url: `/scanners/${queryArg.deviceId}/approve-qc`,
                method: "POST",
            }),
        }),
        scannersControllerDeclineQualityControl: build.mutation<
            ScannersControllerDeclineQualityControlApiResponse,
            ScannersControllerDeclineQualityControlApiArg
        >({
            query: (queryArg) => ({
                url: `/scanners/${queryArg.deviceId}/decline-qc`,
                method: "POST",
            }),
        }),
        scannersControllerCancelActivation: build.mutation<
            ScannersControllerCancelActivationApiResponse,
            ScannersControllerCancelActivationApiArg
        >({
            query: (queryArg) => ({
                url: `/scanners/${queryArg.deviceId}/cancel-activation`,
                method: "POST",
                params: { activationId: queryArg.activationId },
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as inventoryApi };
export type ConfigurationProfilesControllerRetrieveScannerProfilesApiResponse =
    /** status 200 OK */ ConfigurationProfileDtoPagedList;
export type ConfigurationProfilesControllerRetrieveScannerProfilesApiArg = {
    merchantId?: number;
    page?: number;
};
export type ConfigurationProfilesControllerGetDefaultScannerProfileApiResponse =
    /** status 200 OK */ ConfigurationProfileDto;
export type ConfigurationProfilesControllerGetDefaultScannerProfileApiArg = {
    merchantId: number;
};
export type ConfigurationProfilesControllerRetrieveScannerProfileApiResponse =
    /** status 200 OK */ ConfigurationProfileDto;
export type ConfigurationProfilesControllerRetrieveScannerProfileApiArg = {
    profileId: number;
};
export type ConfigurationProfilesControllerUpdateScannerProfileApiResponse =
    /** status 200 OK */ ConfigurationProfileDto;
export type ConfigurationProfilesControllerUpdateScannerProfileApiArg = {
    profileId: number;
    configurationProfileDto: ConfigurationProfileDto;
};
export type ConfigurationProfilesControllerDeleteScannerProfileApiResponse =
    unknown;
export type ConfigurationProfilesControllerDeleteScannerProfileApiArg = {
    profileId: number;
};
export type ConfigurationProfilesControllerUpdateScannerProfileFeatureOverridesApiResponse =
    unknown;
export type ConfigurationProfilesControllerUpdateScannerProfileFeatureOverridesApiArg =
    {
        profileId: number;
        body: {
            [key: string]: string;
        };
    };
export type ScannersControllerUpdateScannerConfigurationApiResponse =
    /** status 200 OK */ ScannerDto;
export type ScannersControllerUpdateScannerConfigurationApiArg = {
    deviceId: string;
    scannerSettingsDto: ScannerSettingsDto;
};
export type DevicesControllerCreateAwsActivationApiResponse =
    /** status 200 OK */ AwsActivationDetailsDto;
export type DevicesControllerCreateAwsActivationApiArg = {
    deviceId: string;
};
export type DevicesControllerConfirmAwsActivationApiResponse =
    /** status 200 OK */ AwsActivationDetailsDto;
export type DevicesControllerConfirmAwsActivationApiArg = {
    deviceId: string;
    activationId?: string;
};
export type ScannersControllerRetrieveScannerApiResponse =
    /** status 200 OK */ ScannerDto;
export type ScannersControllerRetrieveScannerApiArg = {
    deviceId: string;
};
export type ScannersControllerRetrieveScannersApiResponse =
    /** status 200 OK */ ScannerDtoPagedList;
export type ScannersControllerRetrieveScannersApiArg = {
    pageNumber?: number;
    pageSize?: number;
    deviceId?: string;
    merchantId?: number;
    configurationProfileId?: number;
    scannerStatus?: ScannerStatus;
};
export type ScannersControllerImportScannersApiResponse = unknown;
export type ScannersControllerImportScannersApiArg = {
    body: {
        scannerImportCsv?: Blob;
    };
};
export type ScannersControllerCreateLinkRequestApiResponse =
    /** status 200 OK */ LinkRequestDto;
export type ScannersControllerCreateLinkRequestApiArg = {
    region?: BoddRegion;
    merchantId?: number;
};
export type ScannersControllerRetrieveLinkRequestApiResponse =
    /** status 200 OK */ LinkRequestDto;
export type ScannersControllerRetrieveLinkRequestApiArg = {
    merchantId: number;
    code: string;
};
export type ScannersControllerUnlinkScannerApiResponse =
    /** status 200 OK */ UnlinkScannerResult;
export type ScannersControllerUnlinkScannerApiArg = {
    deviceId: string;
};
export type ScannersControllerApproveQualityControlApiResponse =
    /** status 200 OK */ RegistrationResult;
export type ScannersControllerApproveQualityControlApiArg = {
    deviceId: string;
};
export type ScannersControllerDeclineQualityControlApiResponse =
    /** status 200 OK */ RegistrationResult;
export type ScannersControllerDeclineQualityControlApiArg = {
    deviceId: string;
};
export type ScannersControllerCancelActivationApiResponse = unknown;
export type ScannersControllerCancelActivationApiArg = {
    deviceId: string;
    activationId?: string;
};
export type ScanType =
    | "BodyScan"
    | "FootScan"
    | "WellnessScan"
    | "HandScan"
    | "HeadScan"
    | "HealthScan";
export type InstructionsType = "Modest" | "Personal";
export type ExperienceOptionsDto = {
    logoUrl?: string | null;
    primaryColour?: string | null;
    secondaryColour?: string | null;
    completionMessage?: string | null;
    scanCompletedMessage?: string | null;
    identifierName?: string | null;
    quickResponseUrl?: string | null;
    qrInstructions?: string | null;
    quickResponseInstructions?: string | null;
    userScanTypeSelectionEnabled?: boolean;
    fitSelectionOptions?: string[] | null;
    mandatoryScanTypes?: ScanType[] | null;
    instructionsType?: InstructionsType;
    scanReviewEnabled?: boolean;
};
export type ConfigurationProfileDto = {
    id?: number;
    name?: string | null;
    merchantId?: number;
    userGroupId?: number;
    experienceOptions?: ExperienceOptionsDto;
    enabledScanTypes?: ScanType[] | null;
    scanRetentionDays?: number;
    defaultIntegration?: string | null;
    ingressProfile?: string | null;
    scannerFeatureOverrides?: {
        [key: string]: string;
    } | null;
};
export type ConfigurationProfileDtoPagedList = {
    items?: ConfigurationProfileDto[] | null;
    pageNumber?: number;
    totalPages?: number;
    totalCount?: number;
};
export type BoddRegion = "Unknown" | "AU" | "US" | "USWEST";
export type ScannerStatus =
    | "AwaitRegistration"
    | "AwaitQualityControlScanApproval"
    | "QualityControlScanRejected"
    | "AwaitDiagnostics"
    | "AwaitCustomerQualityControlScan"
    | "Inactive"
    | "RequiresCredentials"
    | "Ready";
export type ConfigurationProfileSummaryDto = {
    id?: number;
    name?: string | null;
};
export type ScannerDto = {
    id?: number;
    name?: string | null;
    deviceId?: string | null;
    modelCode?: string | null;
    merchantId?: number;
    merchantName?: string | null;
    region?: BoddRegion;
    pendingSystemsManagerId?: string | null;
    regionApiBase?: string | null;
    scannerStatus?: ScannerStatus;
    configurationProfiles?: ConfigurationProfileSummaryDto[] | null;
    boddPin?: string | null;
    merchantPin?: string | null;
    locationId?: number | null;
    locationName?: string | null;
};
export type ScannerSettingsDto = {
    deviceId?: string | null;
    name?: string | null;
    assignedConfigurationProfiles?: number[] | null;
    boddPin?: string | null;
    merchantPin?: string | null;
};
export type AwsActivationDetailsDto = {
    systemsManagerId?: string | null;
    systemsManagerCode?: string | null;
};
export type ScannerDtoPagedList = {
    items?: ScannerDto[] | null;
    pageNumber?: number;
    totalPages?: number;
    totalCount?: number;
};
export type LinkRequestDto = {
    code?: string | null;
    linkedScanner?: string | null;
};
export type UnlinkScannerResult = {
    deviceId?: string | null;
    merchantId?: number;
};
export type RegistrationResult = {
    scannerStatus?: ScannerStatus;
    merchantId?: number;
};
export const {
    useConfigurationProfilesControllerRetrieveScannerProfilesQuery,
    useConfigurationProfilesControllerGetDefaultScannerProfileQuery,
    useConfigurationProfilesControllerRetrieveScannerProfileQuery,
    useConfigurationProfilesControllerUpdateScannerProfileMutation,
    useConfigurationProfilesControllerDeleteScannerProfileMutation,
    useConfigurationProfilesControllerUpdateScannerProfileFeatureOverridesMutation,
    useScannersControllerUpdateScannerConfigurationMutation,
    useDevicesControllerCreateAwsActivationMutation,
    useDevicesControllerConfirmAwsActivationMutation,
    useScannersControllerRetrieveScannerQuery,
    useScannersControllerRetrieveScannersQuery,
    useScannersControllerImportScannersMutation,
    useScannersControllerCreateLinkRequestMutation,
    useScannersControllerRetrieveLinkRequestQuery,
    useScannersControllerUnlinkScannerMutation,
    useScannersControllerApproveQualityControlMutation,
    useScannersControllerDeclineQualityControlMutation,
    useScannersControllerCancelActivationMutation,
} = injectedRtkApi;
