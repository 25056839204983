import {
    BaseQueryFn,
    createApi,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
    FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query/react';
import { ApiAuth } from "./ApiAuth";
import { BaseQueryApi } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { MaybePromise } from "@reduxjs/toolkit/dist/query/tsHelpers";

interface BaseApiConfig {
    prepareHeaders: (headers: Headers, api: Pick<BaseQueryApi, 'getState' | 'extra' | 'endpoint' | 'type' | 'forced'>) => MaybePromise<Headers>
}

function getCookie(name: string) {
    let cookieValue;
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

const baseApiConfiguration: BaseApiConfig = {
    prepareHeaders: async (headers: Headers, api) => {
        if (ApiAuth.tokenFetcher) {
            const token = await ApiAuth.tokenFetcher();
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }
        }

        const xsrf = getCookie("XSRF-TOKEN");
        if (xsrf) {
            headers.set("X-XSRF-TOKEN", xsrf);
        }
        return headers;
    },
}

type Meta = {
    qryOptions: any
}

const contextNotFoundError = {
    error: {
        status: 400,
        statusText: 'Bad Request',
        data: 'User context not found',
    },
    meta: { response: {} as Response, request: {} as Request }, // hopefully we dont regret this...
}

const rawBaseQuery = fetchBaseQuery({
    baseUrl: 'temp',
    ...baseApiConfiguration,
});

// here instead of authSlize to avoid circular reference
const getRegionUrl = (state: any) => state.auth.merchantContext?.regionMap?.[state.auth.currentRegion] ?? 'unknown'

const performRegionalQuery = async (args: string | FetchArgs, api: BaseQueryApi, extraOptions: {}) => {
    const auth = (api.getState() as any).auth;
    if (!auth?.merchantContext) {
        return contextNotFoundError;
    }

    const path = typeof args === 'string' ? args : args.url;
    const regionUrl = getRegionUrl(api.getState());

    const regionalUrl = `${regionUrl.startsWith("/") ? window.location.origin : ''}${regionUrl}${path}`;
    const adjustedArgs =
        typeof args === 'string' ? regionalUrl : { ...args, url: regionalUrl }
    return rawBaseQuery(adjustedArgs, api, extraOptions);
}

const baseQueryFactory: (baseUrl: string, regionalOverride?: boolean) => BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError,
    {},
    Meta & FetchBaseQueryMeta
> = (baseUrl: string, regionalOverride = false) => {
    const metaBaseQuery: BaseQueryFn<
        string | FetchArgs,
        unknown,
        FetchBaseQueryError,
        {},
        Meta & FetchBaseQueryMeta
    > = async (args, api, extraOptions) => {
        const baseResult = regionalOverride ? await performRegionalQuery(args, api, extraOptions) : await fetchBaseQuery({
            baseUrl: baseUrl,
            ...baseApiConfiguration,
        })(args, api, extraOptions)

        const qryOptions = (extraOptions as any)?.qryOptions ?? {};
        return {
            ...baseResult,
            meta: baseResult.meta && { ...baseResult.meta, qryOptions },
        }
    };
    return metaBaseQuery;
}
export const inventorySplitApi = createApi({
    reducerPath: '/api/inventory',
    baseQuery: baseQueryFactory('/api/inventory', false),
    refetchOnMountOrArgChange: 5,
    tagTypes: ["Scanner"],
    endpoints: () => ({}),
});

export const merchantAdminSplitApi = createApi({
    reducerPath: '/api/merchant-admin',
    baseQuery: baseQueryFactory('/api/merchant-admin', false),
    refetchOnMountOrArgChange: 5,
    tagTypes: ["Merchant", "MerchantUser", "MerchantContact"],
    endpoints: () => ({}),
});

export const scanSplitApi = createApi({
    reducerPath: '/api/scans',
    baseQuery: baseQueryFactory('n/a', true),
    refetchOnMountOrArgChange: 5,
    tagTypes: ["Scans", "SizeMatchResults"],
    endpoints: () => ({}),
});
