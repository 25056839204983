import { useIdleTimer } from 'react-idle-timer';
import { useEffect, useState } from 'react';
import { BroadcastMessage, MessageType } from './useBroadcastChannel';
import useAuthentication from './useAuthentication';

const IDLE_TIMEOUT = 30 * 60 * 1000;

export default function useIdleLogout() {
    const { isAuthenticated, logout } = useAuthentication();
    const [authenticated, setAuthenticated] = useState(isAuthenticated);

    const processBroadcast = (data: BroadcastMessage) => {
        switch (data.type) {
            case MessageType.Login:
                if (!authenticated) {
                    // eslint-disable-next-line no-console
                    setAuthenticated(true);
                    // login(); this might cause recursion...
                }
                return;
            case MessageType.Logout:
                if (authenticated) {
                    // eslint-disable-next-line no-console
                    logout();
                }
                return;
            default:
                // eslint-disable-next-line no-console
                console.error('Unknown broadcast message received', data);
        }
    };

    const performIdleLogout = (broadcaster: (data: string | number | object, emitOnSelf?: boolean) => boolean) => {
        if (isAuthenticated) {
            // eslint-disable-next-line no-console
            console.log('Processing idle logout');
            broadcaster({ type: MessageType.Logout }, true);
        }
    };

    const { getRemainingTime, activate, isLeader, message: broadcast } = useIdleTimer({
        onMessage: (data) => {
            processBroadcast(data);
        },
        timeout: IDLE_TIMEOUT,
        crossTab: true,
        leaderElection: true,
        syncTimers: 1000,
    });

    useEffect(() => {
        setAuthenticated(isAuthenticated);
        if (isAuthenticated) {
            activate();
        }
    }, [isAuthenticated]);

    useEffect(() => {
        // this was necessary because the onIdle callback on useIdleTimer did not seem 100% reliable
        const i = setInterval(() => {
            if (getRemainingTime() <= 0 && isLeader() && isAuthenticated) {
                performIdleLogout(broadcast);
            }
        }, 1000);

        return () => clearInterval(i);
    }, [isAuthenticated]);
}
