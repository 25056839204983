import React, { FC } from 'react';
import { Box, CircularProgress, LinearProgress } from '@mui/material';

interface LoadingComponentProps {
    isLoading: boolean;
    progress?: number;
    children?: React.ReactNode | undefined;
}

const LoadingComponent: FC<LoadingComponentProps> = (props) => {
    const { isLoading, progress, children } = props;
    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {/* eslint-disable-next-line no-nested-ternary */}
            {isLoading ? (
                <Box
                    data-testid="LoadingComponent"
                    sx={{
                        flex: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        my: '32px',
                    }}
                >
                    {progress === -1 ? <CircularProgress /> : (
                        <Box sx={{ width: '50%' }}><LinearProgress variant="determinate" value={progress} /></Box>
                    )}
                </Box>
            ) : children}
        </>
    );
};

export default LoadingComponent;

LoadingComponent.defaultProps = {
    progress: -1,
    children: undefined,
};
