import React, { useEffect } from 'react';
import './App.scss';
import {
    AppBar,
    Box,
    Container,
    CssBaseline,
    IconButton,
    Slide,
    ThemeProvider,
    Toolbar,
    useMediaQuery,
    useScrollTrigger,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Link, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { theme } from './features/common/theme/theme';
import ImpersonateBarComponent from './features/common/components/ImpersonateBarComponent/ImpersonateBarComponent';
import LandingComponent from './features/common/components/LandingComponent/LandingComponent';
import HeaderComponent from './features/common/components/HeaderComponent/HeaderComponent';
import RoutingComponent from './features/common/navigation/RoutingComponent/RoutingComponent';
import useIdleLogout from './features/common/helpers/useIdleLogout';
import boddColours from './variables.module.scss';
import BoddIconComponent from './features/common/components/BoddIconComponent/BoddIconComponent';
import { useAppSelector } from './app/hooks';
import { selectIsBoddUserImpersonatingMerchant } from './features/common/auth/authSlice';
import useAuthentication from './features/common/helpers/useAuthentication';

function App() {
    useAuthentication();
    useIdleLogout();
    const isBoddImpersonating = useAppSelector(selectIsBoddUserImpersonatingMerchant);
    const isMobileLayout = useMediaQuery(theme.breakpoints.down('md'));
    const navigate = useNavigate();
    const location = useLocation();
    const scrollTrigger = useScrollTrigger();
    const drawerWidth = isMobileLayout ? 257 : 92;
    const [menuMobileOpen, setMenuMobileOpen] = React.useState(false);
    const [menuOpen, setOpen] = React.useState(false);

    useEffect(() => {
        setMenuMobileOpen(false);
    }, [location]);

    useEffect(() => {
        setOpen(menuMobileOpen || !isMobileLayout);
    }, [menuMobileOpen, isMobileLayout]);

    const containerTopMargin = () => {
        if (isMobileLayout && isBoddImpersonating) {
            return '99px';
        }
        return isMobileLayout ? '60px' : '0px';
    };

    return (
        <div className="App" data-testid="App">
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    {!isMobileLayout && <ImpersonateBarComponent />}
                    <Routes>
                        <Route
                            path="/"
                            element={(
                                <LandingComponent
                                    drawerWidth={drawerWidth}
                                />
                            )}
                        />
                        <Route
                            path="*"
                            element={(
                                <div
                                    style={{ display: 'flex', flexDirection: 'column' }}
                                >
                                    <HeaderComponent
                                        drawerWidth={drawerWidth}
                                        open={menuOpen}
                                        onClose={() => setMenuMobileOpen(false)}
                                    />
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            flex: 1,
                                        }}
                                    >
                                        <Slide appear={false} direction="down" in={!scrollTrigger}>
                                            <AppBar
                                                position="fixed"
                                                elevation={0}
                                                sx={(t) => ({
                                                    bgcolor: boddColours.white,
                                                    height: isBoddImpersonating ? '99px' : '60px',
                                                    borderBottom: 1,
                                                    borderColor: boddColours.grey50,
                                                    display: { xs: 'flex', sm: 'flex', md: 'none' },
                                                    marginLeft: `${menuOpen ? drawerWidth : 0}px`,
                                                    width: `calc(100% - ${menuOpen ? drawerWidth : 0}px)`,
                                                    overflow: 'hidden',
                                                    left: 0,
                                                    transition:
                                                        t.transitions.create(['margin', 'width'], {
                                                            easing: menuOpen ? t.transitions.easing.easeOut : t.transitions.easing.sharp,
                                                            duration: menuOpen ? t.transitions.duration.enteringScreen : t.transitions.duration.leavingScreen,
                                                        }),
                                                })}
                                            >
                                                <ImpersonateBarComponent />
                                                <Toolbar sx={{ justifyContent: 'space-between' }}>
                                                    <IconButton
                                                        onClick={() => setMenuMobileOpen(!menuOpen)}
                                                    >
                                                        <MenuIcon style={{ color: boddColours.boddBlack }} />
                                                    </IconButton>
                                                    <IconButton
                                                        component={Link}
                                                        to="/"
                                                        sx={{
                                                            '&.MuiButtonBase-root:hover': {
                                                                backgroundColor: 'transparent',
                                                            },
                                                        }}
                                                    >
                                                        <BoddIconComponent
                                                            clicked={() => navigate('/')}
                                                            height="28px"
                                                            width="35px"
                                                        />
                                                    </IconButton>
                                                    <Box sx={{ width: 24 }} />
                                                </Toolbar>
                                            </AppBar>
                                        </Slide>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                flex: 1,
                                            }}
                                        >
                                            <div style={{ width: isMobileLayout ? 0 : drawerWidth }} />
                                            <Container
                                                maxWidth="xl"
                                                sx={(t) => ({
                                                    display: 'flex',
                                                    flex: 1,
                                                    padding: { xs: 0 },
                                                    width: `calc(100% - ${drawerWidth}px)`,
                                                    marginTop: containerTopMargin(),
                                                    transition:
                                                        t.transitions.create(['margin', 'width'], {
                                                            easing: menuOpen ? t.transitions.easing.easeOut : t.transitions.easing.sharp,
                                                            duration: menuOpen ? t.transitions.duration.enteringScreen : t.transitions.duration.leavingScreen,
                                                        }),
                                                })}
                                            >
                                                <Box sx={{
                                                    flexGrow: 1,
                                                    p: 3,
                                                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                                                    display: 'flex',
                                                    padding: { xs: 0 },
                                                    marginRight: '20px',
                                                    marginLeft: '20px',
                                                    marginTop: 0,
                                                }}
                                                >
                                                    <RoutingComponent />
                                                </Box>
                                            </Container>
                                        </div>
                                    </div>
                                </div>
                            )}
                        />
                    </Routes>
                </ThemeProvider>
            </LocalizationProvider>
        </div>
    );
}

export default App;
