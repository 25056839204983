import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { enAU, enGB } from 'date-fns/locale';
import { format as formatDate, isDate } from 'date-fns';

const locales: Locale[] = [enAU, enGB];

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        // lng: 'fr', // if you're using a language detector, do not define the lng option
        fallbackLng: 'en-AU',
        ns: ['common'],
        detection: { order: ['navigator'] },
        backend: {
            loadPath: `/locales/{{lng}}/{{ns}}.json?v=${BUILD_HASH}`,
        },
        interpolation: {
            escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
            format: (value: number | Date, format: string | undefined, lng: string | undefined) => {
                if (isDate(value)) {
                    const locale = locales.find((l) => l.code === lng);
                    if (format === 'date') return formatDate(value, 'P', { locale });
                    if (format === 'datetime') return formatDate(value, 'P p', { locale });
                    if (format === 'datetime2') return formatDate(value, 'P pp', { locale });
                    // Todo: will need to revisit here to internationalise TimeAgo, and remove moment
                    // if (format === 'relative') return formatRelative(value, new Date(), { locale });
                    // if (format === 'ago') {
                    //     return formatDistance(value, new Date(), {
                    //         locale,
                    //         addSuffix: true,
                    //     });
                    // }
                    return formatDate(value, format!, { locale });
                }

                return value.toString();
            },
        },
    });
