import React, { FC } from 'react';
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import { AuthProvider } from 'react-oidc-context';
import { AuthenticationModes, authMode } from '../../helpers/useAuthentication';
import BoddPortalAuthProvider from '../BoddPortalAuthProvider/BoddPortalAuthProvider';

interface BoddAuthProviderProps {
    children: React.ReactNode;
}

const BoddAuthProvider: FC<BoddAuthProviderProps> = ({ children }) => {
    const { d: domain, cId: clientId } = (window as any).boddSettings;
    const navigate = useNavigate();

    const onRedirectCallback = (appState: AppState | undefined) => {
        navigate(appState?.returnTo || window.location.pathname);
    };

    const currentProvider = authMode;

    if (currentProvider === AuthenticationModes.IdentityServerPortalAuth) {
        return (
            <BoddPortalAuthProvider>{children}</BoddPortalAuthProvider>
        );
    }
    if (currentProvider === AuthenticationModes.Auth0PortalAuth) {
        return (
            <BoddPortalAuthProvider>{children}</BoddPortalAuthProvider>
        );
    }
    if (currentProvider === AuthenticationModes.IdentityServer) {
        return (
            <AuthProvider
                monitorSession
                authority={domain}
                // trade off with between user experience and security...
                // userStore={new WebStorageStateStore({ store: window.localStorage })}
                client_id={clientId}
                redirect_uri={window.location.origin}
                scope="openid profile boddhub-api"
                onSigninCallback={(user) => {
                    const returnTo = (user?.state as any)?.returnTo ?? window.location.pathname;
                    navigate(returnTo);
                }}
            >
                {children}
            </AuthProvider>
        );
    }

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            onRedirectCallback={onRedirectCallback}
            authorizationParams={{
                redirect_uri: window.location.origin,
                scope: 'merchant merchant-manager bodd-admin profile email',
                audience: 'https://merchants.bodd.io/api',
            }}
        >
            {children}
        </Auth0Provider>
    );
};

export default BoddAuthProvider;
