import React, { FC } from 'react';
import { AppBar, Box, Divider, IconButton, useMediaQuery } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import { Link, useNavigate } from 'react-router-dom';
import BoddIconComponent from '../BoddIconComponent/BoddIconComponent';
import GreetingComponent from '../GreetingComponent/GreetingComponent';
import SecurityTrimmedNavigationComponent
    from '../../auth/SecurityTrimmedNavigationComponent/SecurityTrimmedNavigationComponent';
import { useAppSelector } from '../../../../app/hooks';
import { selectIsBoddUserImpersonatingMerchant, selectNavigation } from '../../auth/authSlice';
import boddColours from '../../../../variables.module.scss';
import { theme } from '../../theme/theme';

interface HeaderComponentProps {
    drawerWidth: number;
    open: boolean;
    onClose: () => void;
}

const HeaderComponent: FC<HeaderComponentProps> = (props) => {
    const { drawerWidth, open, onClose } = props;

    const navigate = useNavigate();
    const isBoddImpersonating = useAppSelector(selectIsBoddUserImpersonatingMerchant);
    const navigation = useAppSelector(selectNavigation);
    const isMobileLayout = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <div data-testid="HeaderComponent">
            <Drawer
                PaperProps={{
                    sx: {
                        flexShrink: 0,
                        overflowY: 'auto',
                        overflowX: 'scroll',
                        width: drawerWidth,
                        minWidth: drawerWidth,
                        minHeight: '100%',
                        border: 'none',
                        msOverflowStyle: 'none',
                        scrollbarWidth: 'none',
                        '&::-webkit-scrollbar': { display: 'none' },
                        borderRight: 1,
                        borderColor: boddColours.grey50,
                    },
                }}
                sx={{ '& .MuiBackdrop-root': { backgroundColor: 'transparent' } }}
                variant={isMobileLayout ? 'temporary' : 'persistent'}
                anchor="left"
                open={open}
                onClose={onClose}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, m: 0, minHeight: '100%' }}>
                    {isBoddImpersonating && (
                        <AppBar
                            position="relative"
                            elevation={0}
                            sx={{ bgcolor: 'transparent' }}
                        />
                    )}
                    {isMobileLayout ? (
                            <Box
                                sx={{
                                    height: '60px',
                                    borderBottom: 1,
                                    borderColor: boddColours.grey50,
                                }}
                            />
                        )
                        : (
                            <IconButton
                                component={Link}
                                to="/"
                                sx={{
                                    height: '80px',
                                    '&.MuiButtonBase-root:hover': {
                                        backgroundColor: 'transparent',
                                    },
                                }}
                            >
                                <BoddIconComponent clicked={() => navigate('/')} />
                            </IconButton>
                        )}
                    <SecurityTrimmedNavigationComponent navigationItems={navigation} />
                    {!isMobileLayout && <Box sx={{ minHeight: '90px' }} />}
                    <Box sx={{
                        position: isMobileLayout ? 'relative' : 'fixed',
                        bottom: 0,
                        bgcolor: boddColours.white,
                        width: `calc(${drawerWidth}px - 1px)`,
                    }}
                    >
                        <Divider sx={{ borderColor: boddColours.grey50 }} />
                        <GreetingComponent />
                    </Box>
                </Box>
            </Drawer>
        </div>
    );
};

export default HeaderComponent;
