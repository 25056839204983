/* eslint-disable max-len */
import React, { FC, Suspense } from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import AccessDeniedComponent from '../../auth/AccessDeniedComponent/AccessDeniedComponent';
import ProtectedRoute from '../../auth/ProtectedRoute/ProtectedRoute';
import LoginComponent from '../../auth/LoginComponent/LoginComponent';

import {
    BoddRole,
    MerchantAdminRole,
    MerchantOwnerRole,
    MerchantRole,
    QualityAssuranceRole,
    SystemOwnerRole,
} from '../../auth/auth-constants';
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent';
import RegionWrapperComponent from '../RegionWrapperComponent/RegionWrapperComponent';

const ProductListViewComponent = React.lazy(() => import('../../../products/ProductListViewComponent/ProductListViewComponent'));
const ScannerListViewComponent = React.lazy(() => import('../../../inventory/ScannerListViewComponent/ScannerListViewComponent'));
const MerchantListViewComponent = React.lazy(() => import('../../../merchants/MerchantListViewComponent/MerchantListViewComponent'));
const MerchantDetailsViewComponent = React.lazy(() => import('../../../merchants/MerchantDetailsViewComponent/MerchantDetailsViewComponent'));
const ScannerDetailViewComponent = React.lazy(() => import('../../../inventory/ScannerDetailViewComponent/ScannerDetailViewComponent'));
const ProductDetailsViewComponent = React.lazy(() => import('../../../products/ProductDetailsViewComponent/ProductDetailsViewComponent'));
const LinkScannerViewComponent = React.lazy(() => import('../../../inventory/LinkScannerViewComponent/LinkScannerViewComponent'));
const ScannerSessionListViewComponent = React.lazy(() => import('../../../scans/ScannerSessionListViewComponent/ScannerSessionListViewComponent'));
const SessionHeaderViewComponent = React.lazy(() => import('../../../scans/session-details/SessionHeaderViewComponent/SessionHeaderViewComponent'));
const ReportsViewComponent = React.lazy(() => import('../../../reports/ReportsViewComponent/ReportsViewComponent'));
const ConfigurationProfileDetailViewComponent = React.lazy(() => import('../../../inventory/configuration/ConfigurationProfileDetailViewComponent/ConfigurationProfileDetailViewComponent'));
const ConfigurationProfileListViewComponent = React.lazy(() => import('../../../inventory/configuration/ConfigurationProfileListViewComponent/ConfigurationProfileListViewComponent'));

interface RoutingComponentProps {
}

const protectedMerchantsComponent = (
    <ProtectedRoute
        component={() => <Suspense fallback={<LoadingComponent isLoading />}><MerchantListViewComponent /></Suspense>}
        roles={[BoddRole, SystemOwnerRole]}
        path="/merchants"
    />
);

const protectedMerchantsViewComponent = (
    <ProtectedRoute
        component={() => (
            <Suspense fallback={<LoadingComponent isLoading />}>
                <MerchantDetailsViewComponent
                    merchantMode={false}
                />
            </Suspense>
        )}
        roles={[BoddRole, SystemOwnerRole]}
        path="/merchants/:merchantId"
    />
);

const protectedAccountViewComponent = (
    <ProtectedRoute
        component={() => (
            <Suspense fallback={<LoadingComponent isLoading />}>
                <MerchantDetailsViewComponent
                    merchantMode
                />
            </Suspense>
        )}
        roles={[MerchantRole, MerchantAdminRole, MerchantOwnerRole]}
        path="/account"
    />
);

const protectedRegionWrapperComponent = (
    <ProtectedRoute
        component={() => (
            <Suspense
                fallback={<LoadingComponent isLoading />}
            >
                <RegionWrapperComponent />
            </Suspense>
        )}
        path="/:region"
    />
);

const protectedScanListComponent = (
    <ProtectedRoute
        path="/scans"
        roles={[BoddRole, SystemOwnerRole, QualityAssuranceRole, MerchantRole, MerchantAdminRole, MerchantOwnerRole]}
        component={() => (
            <Suspense
                fallback={<LoadingComponent isLoading />}
            >
                <ScannerSessionListViewComponent />
            </Suspense>
        )}
    />
);

const protectedSessionDetailViewComponent = (
    <ProtectedRoute
        path="/scans/:scanId"
        roles={[BoddRole, SystemOwnerRole, QualityAssuranceRole, MerchantRole, MerchantAdminRole, MerchantOwnerRole]}
        component={() => <Suspense fallback={<LoadingComponent isLoading />}><SessionHeaderViewComponent /></Suspense>}
    />
);

const protectedProductComponent = (
    <ProtectedRoute
        component={() => <Suspense fallback={<LoadingComponent isLoading />}><ProductDetailsViewComponent /></Suspense>}
        roles={[MerchantRole, MerchantAdminRole, MerchantOwnerRole]}
        path="/products/:productCode"
    />
);

const protectedProductListComponent = (
    <ProtectedRoute
        component={() => <Suspense fallback={<LoadingComponent isLoading />}><ProductListViewComponent /></Suspense>}
        roles={[MerchantRole, MerchantAdminRole, MerchantOwnerRole]}
        path="/products"
        requiredConfigurationKey="sizeMatchEnabled"
    />
);

const protectedScannersListComponent = (
    <ProtectedRoute
        component={() => <Suspense fallback={<LoadingComponent isLoading />}><ScannerListViewComponent /></Suspense>}
        roles={[BoddRole, SystemOwnerRole, QualityAssuranceRole, MerchantAdminRole, MerchantOwnerRole]}
        path="/scanners"
    />
);

const protectedScannerDetailViewComponent = (
    <ProtectedRoute
        component={() => <Suspense fallback={<LoadingComponent isLoading />}><ScannerDetailViewComponent /></Suspense>}
        roles={[BoddRole, SystemOwnerRole, MerchantAdminRole, MerchantOwnerRole]}
        path="/scanners/:scannerId"
    />
);

const protectedConfigurationListViewComponent = (
    <ProtectedRoute
        component={() => (
            <Suspense
                fallback={<LoadingComponent isLoading />}
            >
                <ConfigurationProfileListViewComponent />
            </Suspense>
        )}
        roles={[MerchantRole, MerchantAdminRole, MerchantOwnerRole, BoddRole, SystemOwnerRole]}
        path="/profiles/:profileId"
    />
);

const protectedConfigurationDetailViewComponent = (
    <ProtectedRoute
        component={() => (
            <Suspense
                fallback={<LoadingComponent isLoading />}
            >
                <ConfigurationProfileDetailViewComponent />
            </Suspense>
        )}
        roles={[MerchantRole, MerchantAdminRole, MerchantOwnerRole, BoddRole, SystemOwnerRole]}
        path="/profiles/:profileId"
    />
);

const protectedLinkScannerViewComponent = (
    <ProtectedRoute
        component={() => <Suspense fallback={<LoadingComponent isLoading />}><LinkScannerViewComponent /></Suspense>}
        roles={[BoddRole, SystemOwnerRole, MerchantAdminRole, MerchantOwnerRole]}
        path="/link-scanner"
    />
);

const protectedReportsViewComponent = (
    <ProtectedRoute
        component={() => <Suspense fallback={<LoadingComponent isLoading />}><ReportsViewComponent /></Suspense>}
        roles={[MerchantAdminRole, MerchantOwnerRole, BoddRole, SystemOwnerRole]}
        path="/reports"
    />
);

const fallbackComponent = () => <h1>Something went wrong.</h1>;

const PrependRegion = () => {
    const p = useParams();
    const original = p['*'];

    return <Navigate to={`/au/${original}`} replace />;
};

const RoutingComponent: FC<RoutingComponentProps> = () => (
    <Suspense fallback={<div data-testid="RoutingComponent">Loading</div>}>
        <Sentry.ErrorBoundary fallback={fallbackComponent}>
            <Routes>
                <Route path="/scans/:any" element={<PrependRegion />} />
                <Route path="/reports" element={<PrependRegion />} />
                <Route
                    path="/:region"
                    element={protectedRegionWrapperComponent}
                >
                    <Route
                        path="scans"
                        element={protectedScanListComponent}
                    />
                    <Route
                        path="scans/:sessionId"
                        element={protectedSessionDetailViewComponent}
                    />
                    <Route
                        path="reports"
                        element={protectedReportsViewComponent}
                    />
                    <Route path="" element={<Navigate to="scans" replace />} />
                </Route>
                <Route
                    path="/merchants/:merchantId"
                    element={protectedMerchantsViewComponent}
                />
                <Route
                    path="/merchants"
                    element={protectedMerchantsComponent}
                />
                <Route
                    path="/products"
                    element={protectedProductListComponent}
                />
                <Route
                    path="/products/:productCode"
                    element={protectedProductComponent}
                />
                <Route
                    path="/scanners"
                    element={protectedScannersListComponent}
                />
                <Route
                    path="/scanners/link"
                    element={protectedLinkScannerViewComponent}
                />
                <Route
                    path="/scanners/:deviceId"
                    element={protectedScannerDetailViewComponent}
                />
                <Route
                    path="/profiles"
                    element={protectedConfigurationListViewComponent}
                />
                <Route
                    path="/profiles/:profileIdParam"
                    element={protectedConfigurationDetailViewComponent}
                />
                <Route path="/account" element={protectedAccountViewComponent} />
                <Route path="/login" element={<LoginComponent />} />
                <Route path="access-denied" element={<AccessDeniedComponent />} />
                {/*
                        Return an empty component, preventing the wildcard "*" route from handling the "/" path
                        since the wildcard navigates to "/" causing an infinite loop.
                        The "/" path should be handled by a route higher in the component hierarchy so this
                        is just a fail-safe.
                    */}
                <Route path="/" element={null} />
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </Sentry.ErrorBoundary>
    </Suspense>
);

export default RoutingComponent;
