import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { RootState } from '../../app/store';

export interface ScansState {
}

const initialState: ScansState = {};

export const scanSizeMatchRecommendationPageSize = 250;

export const scansSlice = createSlice({
    name: 'scans',
    initialState,
    reducers: {},
});

export const selectScansState = (state: RootState) => state.scans;

// eslint-disable-next-line no-empty-pattern
export const {} = scansSlice.actions;

export default scansSlice.reducer;
