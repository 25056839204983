import { useContext, useEffect, useMemo } from 'react';
import { shallowEqual } from 'react-redux';
import { useLazyUserContextControllerRetrieveUserContextQuery } from '../../../services';
import { loginCompleted, loginStarted, logout, selectIsAuthenticated, selectIsAuthLoading } from '../auth/authSlice';
import { BoddPortalAuthContext } from '../auth/BoddAuthProvider/BoddPortalAuthContext';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { MessageType } from './useBroadcastChannel';
import useMessageBroadcaster from './useMessageBroadcaster';

export default function usePortalAuthentication() {
    const authContext = useContext(BoddPortalAuthContext);
    const dispatch = useAppDispatch();
    const stateAuthAuthenticated = useAppSelector(selectIsAuthenticated, shallowEqual);
    const stateAuthLoading = useAppSelector(selectIsAuthLoading, shallowEqual);
    const { broadcast } = useMessageBroadcaster();
    const [retrieveUserContext] = useLazyUserContextControllerRetrieveUserContextQuery();

    useEffect(() => {
        const updateAuth = async () => {
            if (authContext.isLoading && !stateAuthLoading) {
                dispatch(loginStarted());
                return;
            }
            if (!authContext.isAuthenticated && stateAuthLoading) {
                dispatch(logout());
            }

            if (authContext.isAuthenticated && !stateAuthAuthenticated) {
                const u = authContext.user!; // should always be set if we are authed
                const newContext = await retrieveUserContext().unwrap();
                dispatch(loginCompleted({
                    user: { ...u }, // reducer assign issue
                    roles: [...(u?.roles ?? [])],
                    merchantId: u?.merchantId! ?? 0,
                    merchantContext: newContext!,
                    enrolledAuthenticators: u?.enrolledAuthenticators,
                }));
                broadcast({ type: MessageType.Login });
            }
        };
        updateAuth();
    }, [authContext.isLoading]);

    return useMemo(() => ({
        isAuthenticated: authContext.isAuthenticated,
        isLoading: authContext.isLoading,
        login: authContext.login,
        logout: authContext.logout,
        error: authContext.error,
        loginSilent: authContext.loginSilent,
    }), [authContext.isLoading]);
}
