export default class EnvironmentUtils {
    static GetEnvironment(): string {
        const { host } = window.location;
        if (host.indexOf('localhost') > -1) {
            return 'Development';
        }

        if (host.indexOf('int-hub') > -1) {
            return 'Integration';
        }
        if (host.indexOf('uat-hub') > -1) {
            return 'Test';
        }

        return 'Production';
    }

    static IsProduction() {
        return EnvironmentUtils.GetEnvironment() === 'Production';
    }
}
