import React, { FC } from 'react';
import { generatePath, Navigate, Outlet, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../../app/hooks';
import {
    changeRegion,
    selectCurrentRegion,
    selectIsBoddUser,
    selectIsBoddUserImpersonatingMerchant,
    selectIsQaUser,
} from '../../auth/authSlice';

interface RegionWrapperComponentProps {
}

const RegionWrapperComponent: FC<RegionWrapperComponentProps> = () => {
    const p = useParams();
    const { region } = p;
    const isBodd = useAppSelector(selectIsBoddUser);
    const isQa = useAppSelector(selectIsQaUser);
    const isBoddOrQaUser = isBodd || isQa;
    const currentUserRegion = useAppSelector(selectCurrentRegion);
    const isImpersonating = useAppSelector(selectIsBoddUserImpersonatingMerchant);
    const dispatch = useDispatch();

    const mapSegmentToRegion = (regionSegment: string) => {
        switch (regionSegment) {
            case 'us':
                return 'US';
            case 'au':
                return 'AU';
            default:
                return null;
        }
    };

    if (currentUserRegion.toLowerCase() !== region) {
        if (isBoddOrQaUser && !isImpersonating) {
            // the region is different
            //   if the user changed the region they are redirected back to root
            //   so we can treat this as if the user came in with region url not matching their default region
            const regionToUse = mapSegmentToRegion(region!);
            if (regionToUse) {
                // valid region was found so switch the user over
                dispatch(changeRegion(regionToUse));
            } else {
                // invalid region, navigate to au
                // const template = p['*']!.replace(p.region!, '/:region');
                const template = ['/:region', ...p['*']!.split('/').splice(1)].join('/');
                const redirectTo = generatePath(template, { region: 'au' });
                return <Navigate to={redirectTo} />;
            }
        } else {
            return <Navigate to="/access-denied" />;
        }
    }

    return (
        <Outlet />
    );
};

export default RegionWrapperComponent;
