import React, { FC } from 'react';
import { AppBar, Button, Toolbar, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
    impersonateMerchantStop,
    selectCurrentMerchant,
    selectIsBoddUserImpersonatingMerchant,
} from '../../auth/authSlice';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';

interface ImpersonateBarComponentProps {
}

const ImpersonateBarComponent: FC<ImpersonateBarComponentProps> = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const isBoddImpersonating = useAppSelector(selectIsBoddUserImpersonatingMerchant);
    const currentMerchant = useAppSelector(selectCurrentMerchant);

    const handleStopImpersonatingMerchant = () => {
        dispatch(impersonateMerchantStop());
        navigate('/');
    };

    if (isBoddImpersonating) {
        return (
            <AppBar
                data-testid="ImpersonateBarComponent"
                position="relative"
                elevation={0}
                sx={{ bgcolor: 'brandPink.main', alignItems: 'center', justifyContent: 'center' }}
            >
                <Toolbar disableGutters>
                    <Typography noWrap sx={{ color: 'boddBlack.main', fontSize: 12, m: 0, overflow: 'hidden' }}>
                        Currently impersonating
                        {' '}
                        {currentMerchant!.name}
                    </Typography>
                    <Button
                        sx={{
                            ml: 2,
                            height: 24,
                            width: 42,
                            bgcolor: 'boddBlack.main',
                            color: 'white.main',
                            fontSize: 12,
                        }}
                        onClick={handleStopImpersonatingMerchant}
                    >
                        Stop
                    </Button>
                </Toolbar>
            </AppBar>
        );
    }

    return null;
};

export default ImpersonateBarComponent;
