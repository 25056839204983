import React, { FC, ReactNode } from 'react';
import { NavLink as NavLinkBase } from 'react-router-dom';
import { Box, ListItem, ListItemButton, useMediaQuery } from '@mui/material';
import styles from './ListItemLink.module.scss';
import { theme } from '../../theme/theme';

interface ListItemLinkProps {
    icon: ReactNode,
    to?: string,
    text: string | undefined,
    onClick?: () => void,
}

const NavLink = React.forwardRef<any, any>((props, ref) => {
    // eslint-disable-next-line react/prop-types
    const { className, activeClassName, to, children, style } = props;
    return (
        <NavLinkBase
            ref={ref}
            to={to}
            className={({ isActive }) => `${className} ${isActive ? activeClassName : ''}`}
            target={to.startsWith('http') ? '_blank' : '_self'}
            style={style}
        >
            {children}
        </NavLinkBase>
    );
});

const ListItemLink: FC<ListItemLinkProps> = (props) => {
    const { icon, text, to, onClick } = props;
    const isMobileLayout = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <li className={styles.ListItemLink} data-testid="ListItemLink">
            {isMobileLayout ? (
                <div>
                    {onClick
                        ? (
                            <ListItemButton
                                onClick={onClick}
                            >
                                {icon}
                                <Box sx={{ ml: 1.5 }}>{text}</Box>
                            </ListItemButton>
                        ) : (
                            <ListItem
                                component={NavLink}
                                activeClassName={styles.ActiveLink}
                                sx={{ flexDirection: 'row' }}
                                to={to}
                                style={{
                                    padding: '12px 0 12px 24px',
                                    width: '100%',
                                    height: 'auto',
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                }}
                            >
                                {icon}
                                <Box sx={{ ml: 1.5 }}>{text}</Box>
                            </ListItem>
                        )}
                </div>
            ) : (
                <ListItem
                    component={NavLink}
                    activeClassName={styles.ActiveLink}
                    to={to}
                    sx={{ flexDirection: 'column' }}
                >
                    {icon}
                    <Box sx={{ marginTop: '4px' }}>{text}</Box>
                </ListItem>
            )}
        </li>
    );
};

ListItemLink.defaultProps = {
    onClick: undefined,
    to: '',
};

export default ListItemLink;
