/* eslint-disable max-len */
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
// import { User } from '@auth0/auth0-react';
// eslint-disable-next-line import/no-cycle
import { RootState } from '../../../app/store';
import { BoddNavigationItem } from '../model/bodd-navigation-item.model';

// eslint-disable-next-line import/no-cycle
import { BoddRegion, MerchantSummaryDto, MerchantUserContext } from '../../../services';
import {
    BoddRole,
    MerchantAdminRole,
    MerchantOwnerRole,
    QualityAssuranceRole,
    SystemOwnerRole,
} from './auth-constants';
import { Navigation } from '../navigation/navigation';

export interface PortalUser {
    userId: string | undefined,
    merchantId: number | null,
    name: string | undefined,
    email: string | undefined,
    firstName: string | undefined,
    profileImgUrl: string | undefined,
    roles: string[],
    enrolledAuthenticators: string[],
}

const impersonatingMerchantRoles = [MerchantAdminRole];

const getSentryUser: (u: PortalUser) => {
    id: string,
    segment: string,
    username: string
} = (portalUser: PortalUser) => {
    let segment = '';
    if (portalUser.merchantId) {
        segment = `Merchant-${portalUser.merchantId}`;
    } else {
        segment = 'Bodd';
    }
    return {
        id: portalUser.userId!,
        segment,
        username: portalUser.firstName!,
    };
};

export interface AuthState {
    authenticated: boolean,
    user: PortalUser | undefined,
    loading: boolean,
    navigation: BoddNavigationItem[],
    merchantContext: MerchantUserContext | undefined,
    impersonatedMerchant: MerchantSummaryDto | null,
    currentRegion: BoddRegion;

}

const initialState: AuthState = {
    authenticated: false,
    user: undefined,
    loading: false,
    navigation: Navigation.filterForRoles(null, null),
    merchantContext: undefined,
    impersonatedMerchant: null,
    currentRegion: 'AU',
};

const IMPERSONATE_KEY_NAME = 'Bodd_Merch_Imp';
const storeImpersonation = (merchantId: number) => {
    sessionStorage.setItem(IMPERSONATE_KEY_NAME, String(merchantId));
};

const checkImpersonatedMerchant: (user: PortalUser, context: MerchantUserContext) => MerchantSummaryDto | null = (user: PortalUser, merchantContext: MerchantUserContext) => {
    const impersonatingId = sessionStorage.getItem(IMPERSONATE_KEY_NAME);
    if (impersonatingId && user.roles.some((r) => r === BoddRole || r === SystemOwnerRole)) {
        const id = parseInt(impersonatingId, 10);
        const merchant = merchantContext.allMerchants?.find((m) => m.id === id);
        return merchant ?? null;
    }
    return null;
};

const removeStoredImpersonation = () => {
    sessionStorage.removeItem(IMPERSONATE_KEY_NAME);
};

const getConfig = (merchant: MerchantSummaryDto | undefined): { [key: string]: boolean } => ({
    qualityAssuranceHoldEnabled: !!merchant?.qualityAssuranceHoldEnabled,
    sizeMatchEnabled: !!merchant?.sizeMatchEnabled,
});

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginCompleted: (
            state,
            action: PayloadAction<{
                user: PortalUser,
                roles: string[],
                merchantId: number | undefined,
                merchantContext: MerchantUserContext,
                enrolledAuthenticators: string[],
            }>,
        ) => {
            state.loading = false;
            state.authenticated = true;
            const portalUser = action.payload.user;
            portalUser.roles = action.payload.roles;
            portalUser.merchantId = action.payload.merchantId ?? null;
            portalUser.enrolledAuthenticators = action.payload.enrolledAuthenticators;
            state.user = portalUser;
            state.merchantContext = action.payload.merchantContext;
            state.currentRegion = Object.keys(action.payload.merchantContext.regionMap!)[0] as BoddRegion;
            state.navigation = Navigation.filterForRoles(portalUser.roles, getConfig(action.payload.merchantContext?.userMerchant));

            const impersonatingMerchant = checkImpersonatedMerchant(portalUser, action.payload.merchantContext!);
            if (impersonatingMerchant) {
                state.impersonatedMerchant = impersonatingMerchant;
                state.currentRegion = impersonatingMerchant.region!;
                state.navigation = Navigation.filterForRoles(impersonatingMerchantRoles, getConfig(impersonatingMerchant));
            }
            Sentry.setUser(getSentryUser(portalUser));
        },
        loginStarted: (state) => {
            state.loading = true;
            state.authenticated = false;
            state.user = undefined;
            state.navigation = Navigation.filterForRoles(null, null);
            state.merchantContext = undefined;
        },
        logout: (state) => {
            Sentry.setUser(null);
            state.loading = false;
            state.authenticated = false;
            state.user = undefined;
            state.navigation = Navigation.filterForRoles(null, null);
            state.currentRegion = 'AU';
            state.merchantContext = undefined;
            state.impersonatedMerchant = null;
            removeStoredImpersonation();
        },
        impersonateMerchantStart: (
            state,
            action: PayloadAction<({
                merchant: MerchantSummaryDto,
            })>,
        ) => {
            state.impersonatedMerchant = action.payload.merchant;
            storeImpersonation(action.payload.merchant.id!);
            state.currentRegion = action.payload.merchant.region!;
            state.navigation = Navigation.filterForRoles(impersonatingMerchantRoles, getConfig(action.payload.merchant));
        },
        impersonateMerchantStop: (state) => {
            state.impersonatedMerchant = null;
            removeStoredImpersonation();
            state.currentRegion = Object.keys(state.merchantContext!.regionMap!)[0] as BoddRegion;
            state.navigation = Navigation.filterForRoles(state.user!.roles, getConfig(state.merchantContext?.userMerchant));
        },
        changeRegion: (state, action: PayloadAction<BoddRegion>) => {
            state.currentRegion = action.payload;
        },
    },
});

const selectCurrentRolesInternal = (state: RootState) => (state.auth.impersonatedMerchant ? impersonatingMerchantRoles : state.auth.user?.roles ?? []);
const selectCurrentMerchantInternal = (state: RootState) => state.auth.impersonatedMerchant ?? state.auth.merchantContext?.userMerchant ?? undefined;
const selectAllMerchantsInternal = (state: RootState) => state.auth.merchantContext?.allMerchants ?? [];
const selectCurrentRegionInternal = (state: RootState) => state.auth.currentRegion;

export const selectIsAuthLoading = (state: RootState) => state.auth.loading;
export const selectIsAuthenticated = (state: RootState) => state.auth.authenticated;
export const selectAuthenticatedUser = (state: RootState) => state.auth?.user || null;
export const selectIsEnrolledInAuthenticatorApp = (state: RootState) => state.auth.user?.enrolledAuthenticators.includes('AUTHENTICATOR_APP') ?? false;

export const selectAllMerchants = createSelector([selectCurrentMerchantInternal, selectAllMerchantsInternal], (currentMerchant, allMerchants) => {
    if (currentMerchant) {
        return [currentMerchant];
    }
    return allMerchants;
});

export const selectMerchantNameMap = createSelector([selectAllMerchants], (merchants) => merchants.reduce((a: {
    [key: number]: string
}, c) => {
    if (typeof c?.id === 'number') {
        a[c.id] = c.name ?? '';
    }
    return a;
}, {}));

export const selectIsBoddUser = (state: RootState) => (state.auth.impersonatedMerchant ? false : state.auth.user?.roles.some((r) => r === BoddRole || r === SystemOwnerRole)) ?? false;
export const selectIsQaUser = (state: RootState) => state.auth.user?.roles.some((r) => r === QualityAssuranceRole) ?? false;
export const selectIsBoddUserImpersonatingMerchant = (state: RootState) => (state.auth.user?.roles.some((r) => r === BoddRole || r === SystemOwnerRole) ?? false) && state.auth.impersonatedMerchant;
export const selectNavigation = createSelector([(state: RootState) => state.auth.navigation, (state: RootState) => state.auth.currentRegion], (navigation, region) => {
    const regionPlaceholder = '%region%';
    return navigation.map((n) => ({ ...n, url: n.url.replace(regionPlaceholder, region.toLowerCase()) }));
});

// returns the merchantId - taking into account impersonation if it is active.
export const selectCurrentMerchantId = createSelector(
    [selectCurrentMerchantInternal],
    (merchantId) => merchantId?.id,
);

export const selectCurrentMerchant = createSelector([selectCurrentMerchantInternal], (merchant) => merchant);

export const selectCurrentMerchantConfigurationEnabled = (configKey?: string) => (state: RootState) => {
    if (!configKey) {
        return true;
    }
    const merchant = selectCurrentMerchantInternal(state);
    if (merchant) {
        return (merchant as any)[configKey] === true;
    }
    return true;
};

export const selectCurrentRoles = createSelector([selectCurrentRolesInternal], (roles) => roles);
export const selectCurrentRegion = createSelector([selectCurrentRegionInternal], (region) => region);
export const selectHasAdminRole = createSelector([selectCurrentRolesInternal], (roles) => roles?.some((r) => r === MerchantAdminRole || r === BoddRole || r === MerchantOwnerRole || r === SystemOwnerRole) ?? false);
export const selectHasOwnerRole = createSelector([selectCurrentRolesInternal], (roles) => roles?.some((r) => r === MerchantOwnerRole || r === SystemOwnerRole) ?? false);
export const selectHasSystemOwnerRole = createSelector([selectCurrentRolesInternal], (roles) => roles?.some((r) => r === SystemOwnerRole) ?? false);
export const selectGreatestRole = createSelector([selectCurrentRolesInternal], (roles) => {
    if (roles.length === 1) {
        return roles[0];
    }
    if (SystemOwnerRole in roles) {
        return SystemOwnerRole;
    }
    if (MerchantOwnerRole in roles) {
        return MerchantOwnerRole;
    }
    if (BoddRole in roles) {
        return BoddRole;
    }
    if (MerchantAdminRole in roles) {
        return MerchantAdminRole;
    }
    if (QualityAssuranceRole in roles) {
        return QualityAssuranceRole;
    }
    if (BoddRole in roles) {
        return BoddRole;
    }
    return 'None';
});

export const {
    loginStarted,
    loginCompleted,
    logout,
    impersonateMerchantStart,
    impersonateMerchantStop,
    changeRegion,
} = authSlice.actions;

export default authSlice.reducer;
