import React, { ComponentType, FC, useEffect, useMemo } from 'react';
import { WithAuthenticationRequiredOptions } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '../../../../app/hooks';
import { selectCurrentMerchantConfigurationEnabled, selectCurrentRoles, selectIsAuthenticated } from '../authSlice';
import useAuthentication from '../../helpers/useAuthentication';

const defaultReturnTo = (): string => `${window.location.pathname}${window.location.search}`;
// eslint-disable-next-line react/jsx-no-useless-fragment
const defaultOnRedirecting = (): JSX.Element => <></>;

interface WithRoleAuthorizationRequiredOptions {
    roles?: string[];
}

interface WithMerchantConfigurationOptions {
    requiredConfigurationKey?: string;
}

/* this used to be separate but had to combine them for it to work properly without rerenders - maybe we will come back here later and split
const withRoleAuthorisationRequired = <P extends object>(
    component: React.ReactElement<P>,
    options: WithRoleAuthorizationRequiredOptions): FC<P> => function getOptions(): React.ReactElement<P> {
    const auth = useAppSelector(selectAuth);
    const [isAuthorised, setIsAuthorised] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        const userRoles = auth.user?.roles ?? [];
        const authed = !options.roles || userRoles.some((ur) => options.roles.some((r) => ur === r));
        if (authed) {
            setIsAuthorised(true);
        } else if (!auth.loading) {
            navigate('/access-denied');
        }
    }, [auth]);
    return isAuthorised ? component : (
        <div />
    );
};
*/

const withAuth = <P extends object>(
    Component: ComponentType<P>,
    options: WithAuthenticationRequiredOptions & WithRoleAuthorizationRequiredOptions & WithMerchantConfigurationOptions,
): FC<P> => (props): JSX.Element => {
    const { isAuthenticated: idpAuthenticated, isLoading, login, loginSilent, error } = useAuthentication();

    const {
        returnTo = defaultReturnTo,
        onRedirecting = defaultOnRedirecting,
        loginOptions,
    } = options;
    const isAuthenticated = useAppSelector(selectIsAuthenticated);
    const userRoles = useAppSelector(selectCurrentRoles);
    const configurationEnabled = useAppSelector(selectCurrentMerchantConfigurationEnabled(options.requiredConfigurationKey));

    /*
    const currentMerchant = useAppSelector(selectCurrentMerchant) as any;
    const configurationEnabled = !currentMerchant // bodd user, not impersonating
        || (!!options.requiredConfigurationKey && currentMerchant[options.requiredConfigurationKey] === true) // configuration present and enabled
        || !options.requiredConfigurationKey; // configuration not required
     */

    const isRoleAuthorised = (roles: string[]) => !options.roles || roles.some((ur) => options.roles!.some((r) => ur === r));
    const routeIsAuthenticated = idpAuthenticated;
    useEffect(() => {
        if (isLoading || routeIsAuthenticated || error) {
            return;
        }
        (async (): Promise<void> => {
            await loginSilent();
        })();
    }, [
        isLoading,
        routeIsAuthenticated,
        login,
        loginOptions,
        returnTo,
    ]);
    // eslint-disable-next-line react/jsx-props-no-spreading
    const c = useMemo(() => <Component {...props} />, [Component, props]);
    if (isAuthenticated) {
        if (isRoleAuthorised(userRoles) && configurationEnabled) {
            return c;
        }
        return <Navigate to="/access-denied" />;
    }
    return onRedirecting();
};

interface ProtectedRouteProps {
    component: any;
    roles?: string[];
    path: string;
    requiredConfigurationKey?: string;
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({ component, roles, requiredConfigurationKey, ...args }) => {
    const wrapped = withAuth(component, { roles, requiredConfigurationKey });
    return wrapped({ ...args });
};

export default ProtectedRoute;
