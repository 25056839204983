import { useIdleTimer } from 'react-idle-timer';

export default function useMessageBroadcaster() {
    const { message } = useIdleTimer({
        crossTab: true,
    });
    return {
        broadcast: message,
    };
}
