import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
// eslint-disable-next-line import/no-cycle
import { RootState } from '../../app/store';
import { Fit, ProductDetailDto, SizeChartDto } from '../../services';
import { Measure } from '../common/measure-utils';

export interface ProductsState {
    addingNew: boolean;
    hasFitChanges: boolean;
    sizeChart: SizeChartDto | null;
    productCode: string | null;
    productMerchantId: number | null;
    showSizeCodes: boolean;
    showMidpoints: boolean;
    measure: Measure;
}

const initialState: ProductsState = {
    addingNew: false,
    hasFitChanges: false,
    sizeChart: null,
    productCode: null,
    productMerchantId: null,
    showSizeCodes: false,
    showMidpoints: false,
    measure: Measure.mm,
};

export const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        createProduct: (state) => {
            state.addingNew = true;
        },
        completeCreateProduct: (state) => {
            state.addingNew = false;
        },
        productLoaded: (state, action: PayloadAction<ProductDetailDto | undefined>) => {
            state.sizeChart = action.payload?.sizing ?? null;
            state.productCode = action.payload?.productCode ?? null;
            state.productMerchantId = action.payload?.merchantId ?? null;
        },
        fitVariantAdded: (state, action: PayloadAction<Fit>) => {
            if (state.sizeChart?.fits) {
                state.sizeChart.fits.push(action.payload);
            } else if (state.sizeChart) {
                state.sizeChart.fits = [action.payload];
            }
            state.hasFitChanges = true;
        },
        fitVariantUpdated: (state, action: PayloadAction<{ fit: Fit, index: number }>) => {
            if (state.sizeChart?.fits) {
                state.sizeChart.fits[action.payload.index] = action.payload.fit;
                state.hasFitChanges = true;
            }
        },
        fitVariantDeleted: (state, action: PayloadAction<{ index: number }>) => {
            if (state.sizeChart?.fits) {
                state.sizeChart!.fits.splice(action.payload.index, 1);
                state.hasFitChanges = true;
            }
        },
        sizeChartSaved: (state) => {
            state.hasFitChanges = false;
        },
        showSizeCodesUpdated: (state, action: PayloadAction<boolean>) => {
            state.showSizeCodes = action.payload;
        },
        showMidpointsUpdated: (state, action: PayloadAction<boolean>) => {
            state.showMidpoints = action.payload;
        },
        measureUpdated: (state, action: PayloadAction<Measure>) => {
            state.measure = action.payload;
        },
    },
});

export const selectProductsState = (state: RootState) => state.products;

export const selectSizeChart = (state: RootState) => state.products.sizeChart;

export const selectFits = (state: RootState) => state.products.sizeChart?.fits;

export const selectHasFitChanges = (state: RootState) => state.products.hasFitChanges;

export const selectProductCode = (state: RootState) => state.products.productCode;

export const selectProductMerchantId = (state: RootState) => state.products.productMerchantId;

export const selectFit = (fitIndex: number) => (state: RootState) => {
    const { products } = state;
    return products.sizeChart?.fits ? products.sizeChart?.fits[fitIndex] : null;
};

export const selectShowSizeCodes = (state: RootState) => state.products.showSizeCodes;

export const selectShowMidpoints = (state: RootState) => state.products.showMidpoints;

export const selectFitsLength = (state: RootState) => state.products.sizeChart?.fits?.length ?? 0;

export const selectFitNames = createSelector([selectFits], (fits) => fits?.map((f) => f.fitName) ?? [] as string[]);

export const selectMeasure = (state: RootState) => state.products.measure;

export const {
    createProduct,
    completeCreateProduct,
    productLoaded,
    fitVariantAdded,
    fitVariantUpdated,
    fitVariantDeleted,
    sizeChartSaved,
    showSizeCodesUpdated,
    showMidpointsUpdated,
    measureUpdated,
} = productsSlice.actions;

export default productsSlice.reducer;
