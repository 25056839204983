import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import styles from './AccessDeniedComponent.module.scss';
import { selectAuthenticatedUser } from '../authSlice';
import { useAppSelector } from '../../../../app/hooks';

interface AccessDeniedComponentProps {
}

const AccessDeniedComponent: FC<AccessDeniedComponentProps> = () => {
    const user = useAppSelector(selectAuthenticatedUser);

    return (
        <div className={styles.AccessDeniedComponent} data-testid="AccessDeniedComponent">
            <Stack sx={{ mt: 4 }}>
                <Typography variant="h2">Access Denied</Typography>
                {user && user.roles.length === 0 ? (
                    <Typography>You have not been assigned any roles. Please contact support</Typography>) : null}
            </Stack>
        </div>
    );
};

export default AccessDeniedComponent;
