import React, { FC } from 'react';
import { Button } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import useAuthentication from '../../helpers/useAuthentication';
import { ArrowIcon } from '../../components/IconSelectorComponent/IconSelectorComponent';

interface LoginButtonProps {
}

const LoginButton: FC<LoginButtonProps> = () => {
    const { login } = useAuthentication();

    const [searchParams] = useSearchParams();
    const loginWithContext = () => {
        const returnTo = searchParams.get('returnTo');
        if (returnTo) {
            login(({ appState: { returnTo } }));
        } else {
            login();
        }
    };

    return (
        <Button
            data-testid="LoginButton"
            color="primary"
            endIcon={<ArrowIcon color="white" />}
            onClick={() => loginWithContext()}
        >
            Log In
        </Button>
    );
};

export default LoginButton;
