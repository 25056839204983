import React, { FC } from 'react';
import { List } from '@mui/material';
import ListItemLink from '../../components/ListItemLink/ListItemLink';
import { BoddNavigationItem } from '../../model/bodd-navigation-item.model';
import NavigationIconSelectorComponent
    from '../../components/NavigationIconSelectorComponent/NavigationIconSelectorComponent';

interface SecurityTrimmedNavigationComponentProps {
    navigationItems: BoddNavigationItem[];
}

const SecurityTrimmedNavigationComponent: FC<SecurityTrimmedNavigationComponentProps> = (props) => {
    const { navigationItems } = props;
    return (
        <List data-testid="SecurityTrimmedNavigationComponent">
            {navigationItems.map((n) => (
                <ListItemLink
                    key={n.title}
                    to={n.url}
                    text={n.title}
                    icon={<NavigationIconSelectorComponent icon={n.icon} />}
                />
            ))}
        </List>
    );
};

export default SecurityTrimmedNavigationComponent;
