import React, { FC, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Fade, Stack, Typography, useMediaQuery } from '@mui/material';
import { TransitionGroup } from 'react-transition-group';
import { useAppSelector } from '../../../../app/hooks';
import { selectCurrentRegion, selectIsAuthenticated, selectIsAuthLoading } from '../../auth/authSlice';
import landingPageImage1 from '../../../../assets/landing-page-image-1.jpg';
import landingPageImage2 from '../../../../assets/landing-page-image-2.jpg';
import landingPageImage3 from '../../../../assets/landing-page-image-3.jpg';
import { MeasurementIcon, ScannersIcon, ScansIcon } from '../IconSelectorComponent/IconSelectorComponent';
import LoginButton from '../../auth/LoginButton/LoginButton';
import HeaderComponent from '../HeaderComponent/HeaderComponent';
import ModalDialogComponent from '../ModalDialogComponent/ModalDialogComponent';
import boddColours from '../../../../variables.module.scss';
import { theme } from '../../theme/theme';

interface LandingComponentProps {
    drawerWidth: number;
}

const LandingComponent: FC<LandingComponentProps> = (props) => {
    const { drawerWidth } = props;

    // const { isAuthenticated, isLoading: isAuthLoading } = useAuthentication();
    const isAuthenticated = useAppSelector(selectIsAuthenticated);
    const isAuthLoading = useAppSelector(selectIsAuthLoading);
    const currentRegionPath = useAppSelector(selectCurrentRegion).toLowerCase();
    const ref = useRef<HTMLObjectElement>(null);
    const [joinModalOpen, setJoinModalOpen] = useState(false);
    const [step, setStep] = useState<number>(0);
    const isMobileLayout = useMediaQuery(theme.breakpoints.down('md'));
    const caurasalStepOpacity = isMobileLayout ? 0.3 : 0.2;
    const caurasalTransitionPeriod = 1500;
    const caurasalStepPeriod = 10000;
    const navigate = useNavigate();

    useEffect(() => {
        const interval = setInterval(() => {
            setStep((step + 1) % 3);
        }, caurasalStepPeriod);
        return () => clearInterval(interval);
    }, [step]);

    const click = () => window.open('https://bodd.io', '_blank');

    const attachClick = (e: any) => {
        e.target.contentDocument.addEventListener('click', click);
        e.target.contentDocument.children[0].style.cursor = 'pointer';
    };

    useEffect(() => {
        const obj = ref.current;
        if (obj) {
            obj.addEventListener('load', attachClick);
        }

        return () => {
            if (obj) {
                obj.removeEventListener('load', attachClick);
            }
        };
    }, [isAuthLoading]);

    useEffect(() => {
        if (isAuthenticated) {
            navigate(`/${currentRegionPath}/scans`);
        }
    }, [isAuthenticated]);

    // if (isAuthenticated) {
    //     return <Navigate to={`/${currentRegionPath}/scans`} />;
    // }

    if (isAuthLoading) {
        return (
            <HeaderComponent
                drawerWidth={drawerWidth}
                open
                onClose={() => null}
            />
        );
    }

    const handleCarouselStepClick = (stepIndex: number) => {
        setStep(stepIndex);
    };

    const carousel = [
        {
            Icon: <ScannersIcon
                fontSize={isMobileLayout ? '40px' : '48px'}
                color={isMobileLayout ? boddColours.white : boddColours.boddBlack}
            />,
            text: 'Configure scanner set up & account settings',
            image: landingPageImage1,
            imageLabel: 'Person unpacking scanner',
        },
        {
            Icon: <ScansIcon
                fontSize={isMobileLayout ? '40px' : '48px'}
                color={isMobileLayout ? boddColours.white : boddColours.boddBlack}
            />,
            text: 'Manage scans & scanner experience',
            image: landingPageImage2,
            imageLabel: 'Person getting scanned',
        },
        {
            Icon: <MeasurementIcon
                fontSize={isMobileLayout ? '40px' : '48px'}
                color={isMobileLayout ? boddColours.white : boddColours.boddBlack}
            />,
            text: 'View insights & recommendations',
            image: landingPageImage3,
            imageLabel: 'People looking at a laptop',
        },
    ];

    return (
        <div
            data-testid="LandingComponent"
            style={{
                display: 'flex',
                alignItems: 'stretch',
                flexDirection: isMobileLayout ? 'column-reverse' : 'row',
                flex: 1,
                height: '100%',
                background: isMobileLayout ? 'linear-gradient(0deg, #000 50%, rgba(0, 0, 0, 0.00) 95%)' : boddColours.grey200,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: isMobileLayout ? 'column-reverse' : 'column',
                    p: isMobileLayout ? 0 : 5,
                    borderRadius: isMobileLayout ? '0px' : '0px 24px 24px 0px',
                    width: isMobileLayout ? '100%' : 'auto',
                    bgcolor: isMobileLayout ? boddColours.boddBlack : boddColours.white,
                    zIndex: 200,
                    maxWidth: isMobileLayout ? '100%' : 400,
                }}
            >
                <Stack
                    direction="column"
                    justifyContent="flex-start"
                    alignItems={isMobileLayout ? 'center' : 'flex-start'}
                    sx={{
                        borderRadius: isMobileLayout ? '20px 20px 0px 0px ' : '0px',
                        bgcolor: boddColours.white,
                        p: isMobileLayout ? 3 : 0,
                        pt: isMobileLayout ? 3.5 : 0,
                    }}
                >
                    <object
                        ref={ref}
                        height="70px"
                        width="70px"
                        type="image/svg+xml"
                        data="/bodd-logo.svg"
                        data-testid="BoddIconComponent"
                        aria-label="Bodd"
                    />
                    <Typography
                        variant="h1"
                        sx={{
                            mt: 2.75,
                            letterSpacing: 0.15,
                            fontSize: isMobileLayout ? '2.375em' : '3.375em',
                        }}
                    >
                        BoddHub
                    </Typography>
                    <div>
                        <Button
                            variant="outlined"
                            sx={{ mr: '7px' }}
                            onClick={() => setJoinModalOpen(true)}
                        >
                            Join
                        </Button>
                        <LoginButton />
                    </div>
                </Stack>
                <div
                    style={{ flexGrow: 1 }}
                />
                <Box
                    sx={{
                        p: isMobileLayout ? 3 : 0,
                        pt: 0,
                        mt: isMobileLayout ? -4 : 0,
                    }}
                >
                    <TransitionGroup>
                        <Fade
                            key={step}
                            exit={false}
                            timeout={{ appear: caurasalTransitionPeriod, enter: caurasalTransitionPeriod }}
                        >
                            <Stack
                                sx={{
                                    alignItems: isMobileLayout ? 'center' : 'flex-start',
                                }}
                            >
                                {carousel[step].Icon}
                                <Typography
                                    variant="h2"
                                    sx={{
                                        mt: '12px',
                                        mb: 4,
                                        mx: isMobileLayout ? 2 : 0,
                                        color: isMobileLayout ? boddColours.white : boddColours.boddBlack,
                                        textAlign: isMobileLayout ? 'center' : 'left',
                                        fontSize: isMobileLayout ? '1.25em' : '1.6875em',
                                    }}
                                >
                                    {carousel[step].text}
                                </Typography>
                            </Stack>
                        </Fade>
                    </TransitionGroup>
                    <div
                        style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}
                    >
                        <Button
                            sx={{
                                flexGrow: 1,
                                p: 0,
                                height: '3px',
                                bgcolor: isMobileLayout ? boddColours.white : boddColours.boddBlack,
                                opacity: step === 0 ? '1' : caurasalStepOpacity,
                                '&:hover': { opacity: 1, backgroundColor: boddColours.brandBlue },
                            }}
                            onClick={() => handleCarouselStepClick(0)}
                            disableElevation
                        />
                        <Button
                            sx={{
                                flexGrow: 1,
                                p: 0,
                                height: '3px',
                                bgcolor: isMobileLayout ? boddColours.white : boddColours.boddBlack,
                                opacity: step === 1 ? '1' : caurasalStepOpacity,
                                '&:hover': { opacity: 1, backgroundColor: boddColours.brandBlue },
                            }}
                            onClick={() => handleCarouselStepClick(1)}
                            disableElevation
                        />
                        <Button
                            sx={{
                                flexGrow: 1,
                                p: 0,
                                height: '3px',
                                bgcolor: isMobileLayout ? boddColours.white : boddColours.boddBlack,
                                opacity: step === 2 ? '1' : caurasalStepOpacity,
                                '&:hover': { opacity: 1, backgroundColor: boddColours.brandBlue },
                            }}
                            onClick={() => handleCarouselStepClick(2)}
                            disableElevation
                        />
                    </div>
                </Box>
            </Box>

            <TransitionGroup component={null}>
                <Fade
                    key={step}
                    exit={false}
                    timeout={{ appear: caurasalTransitionPeriod, enter: caurasalTransitionPeriod }}
                >
                    <div
                        style={{
                            flexGrow: 1,
                            background:
                                isMobileLayout ? `linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0.00) 42.51%), url(${carousel[step].image})` : `url(${carousel[step].image})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'top',
                            marginLeft: isMobileLayout ? '0px' : '-24px',
                            zIndex: 100,
                        }}
                    />
                </Fade>
            </TransitionGroup>
            <ModalDialogComponent
                setOpen={setJoinModalOpen}
                title="How to join BoddHub"
                open={joinModalOpen}
                onConfirm={() => setJoinModalOpen(false)}
                confirmText="Ok got it"
            >
                To join, you need to be invited to your company’s Bodd account. Contact your company administrator to
                send you an invite.
            </ModalDialogComponent>
        </div>
    );
};

export default LandingComponent;
