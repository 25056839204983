import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.scss';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import ErrorIcon from '@mui/icons-material/Error';
import * as Sentry from '@sentry/react';
import App from './App';
import { store } from './app/store';
import * as serviceWorker from './serviceWorker';
import EnvironmentUtils from './features/common/environment-utils';
import './i18n';
import BoddAuthProvider from './features/common/auth/BoddAuthProvider/BoddAuthProvider';

Sentry.init({
    dsn: 'https://1fa4a775aee941f3887439e1c9f54826@o4505046372253696.ingest.sentry.io/4505046375202816',
    integrations: [new Sentry.BrowserTracing(), new Sentry.GlobalHandlers({
        onunhandledrejection: false,
        onerror: true,
    })],
    environment: EnvironmentUtils.GetEnvironment(),
    // Performance Monitoring
    tracesSampleRate: EnvironmentUtils.IsProduction() ? 0.5 : 1.0, // we will lower this at some point
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <SnackbarProvider
                    maxSnack={3}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    iconVariant={{ error: <ErrorIcon sx={{ mr: 1 }} /> }}
                >
                    <BoddAuthProvider>
                        <App />
                    </BoddAuthProvider>
                </SnackbarProvider>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>,
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
