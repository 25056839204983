import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';
import styles from './LoginComponent.module.scss';
import useAuthentication from '../../helpers/useAuthentication';

interface LoginComponentProps {
}

const LoginComponent: FC<LoginComponentProps> = () => {
    const { login, isAuthenticated } = useAuthentication();

    if (!isAuthenticated) {
        login();
    }

    return (
        <div className={styles.LoginComponent} data-testid="LoginComponent">
            <Navigate to="/" />
        </div>
    );
};

export default LoginComponent;
