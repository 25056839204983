import React, { Children, cloneElement, FC } from 'react';
import styles from './BoddOnlyComponent.module.scss';
import { useAppSelector } from '../../../../app/hooks';
import { selectIsBoddUser, selectIsQaUser } from '../../auth/authSlice';

interface BoddOnlyComponentProps {
    accessibleToQa?: boolean;
    children: React.ReactNode;
}

const BoddOnlyComponent: FC<BoddOnlyComponentProps> = ({ accessibleToQa, children }) => {
    const isBoddUser = useAppSelector(selectIsBoddUser);
    const isQaUser = useAppSelector(selectIsQaUser);
    const isBoddOrQaUser = isBoddUser || isQaUser;

    if (accessibleToQa) {
        if (!isBoddOrQaUser) {
            return null;
        }
    } else if (!isBoddUser) {
        return null;
    }

    const StyledChildren = () => Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
            return child;
        }

        return cloneElement(child, {
            ...{ className: `${child.props.className} ${styles.BoddOnlyComponent}` },
        });
    });

    return <StyledChildren />;
};

BoddOnlyComponent.defaultProps = {
    accessibleToQa: false,
};

export default BoddOnlyComponent;
