import React, { FC } from 'react';
import {
    AccountIcon,
    ScannerProfileIcon,
    HelpIcon,
    MerchantsIcon,
    ProductsIcon,
    ReportsIcon,
    ScannersIcon,
    ScansIcon,
} from '../IconSelectorComponent/IconSelectorComponent';

interface NavigationIconSelectorComponentProps {
    icon: string;
}

const NavigationIconSelectorComponent: FC<NavigationIconSelectorComponentProps> = ({ icon }) => {
    switch (icon) {
        case 'scans':
            return <ScansIcon />;
        case 'scanners':
            return <ScannersIcon />;
        case 'profile':
            return <ScannerProfileIcon />;
        case 'reports':
            return <ReportsIcon />;
        case 'products':
            return <ProductsIcon />;
        case 'help':
            return <HelpIcon />;
        case 'merchants':
            return <MerchantsIcon />;
        case 'account':
            return <AccountIcon />;
        default:
            return null;
    }
};

export default NavigationIconSelectorComponent;
